import Admin from '@/app/admin/router/router-const';
import Auth from '@/app/auth/core/router-const';

export default Object.freeze({
  ...Admin,
  ...Auth,
  CRM: 'crm-layout',
  NOT_FOUND: 'not_found',
  SERVER_ERROR: 'server_error'
});
