import API  from "@/services/api/api-products"

class Product {
  constructor(item = {}) {
    this.name = item.name
    this.isExtended = item.is_extended
  }

}
export default {
  namespaced: true,

  state: () => ({
    items: null
  }),

  getters: {
    items: state => state.items,
    has: state => state.items ? true : false,
    getItemBy: state =>
      ({field, value}) => {
        if(!state.items) return null
        let currentItem = state.items.find(item => item[field] === value)
        if(!currentItem || currentItem < 0) return null
        return currentItem
      }
  },

  mutations: {
    set(state, items) {
      state.items = items.map(item => new Product(item))
    }
  },

  actions: {
    async list({state, commit}, params) {
      try {
        if(params && params.cache && state.items) return state.items
        let res = await API.list()
        commit('set', res)
        return res
      } catch (error) {
        console.log(error, 'crmProductNames')
        return Promise.reject(error)
      }
    },
  }
}