import ROUTER_NAMES from '@/router/router-const';
import candidates from '../modules/candidates/core/candidates-router';
import AdminLayout from '../Admin-layout.vue';
import resultsRouter from '../modules/results/core/results-router';
import slots from '../modules/slots/core/slots-router';
import cbrAcc from '../modules/cbr_accounts/core/cbrAcc-router';
import cbr from '../modules/cbr/core/cbr-router';
import courseManagment from '../modules/course_managment/core/cm-router';
import courseLeaders from '../modules/course_leaders/core/courseLeadersRouter';
import drivingSchools from '../modules/driving_schools/core/drivingSchool-router';
import taskApplications from '../modules/task_applications/core/taskApplications-router';
import reapplyApplications from '../modules/reapply_applications/core/reapplyApplications-router';
import feedbackRouter from '../modules/feedback/core/feedback-router';

export default {
  path: '/admin',
  component: AdminLayout,
  name: ROUTER_NAMES.CRM,
  children: [
    ...candidates,
    ...resultsRouter,
    ...slots,
    ...cbr,
    ...cbrAcc,
    ...courseManagment,
    ...courseLeaders,
    ...drivingSchools,
    ...taskApplications,
    ...reapplyApplications,
    ...feedbackRouter,
    {
      path: '*',
      redirect: { name: ROUTER_NAMES.NOT_FOUND }
    }
  ],
  meta: {
    requiresAuth: true
  }
};
