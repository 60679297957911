<template lang="pug">
aside.sidebar-mobile
  router-link(
    :to="{name: $ROUTER_NAMES.COURSES, query: {}}"
    v-show="currentPerms.has(CRM_PERMS.COURSES) || currentPerms.has(CRM_PERMS.COURSES_LEADERS)"
  ).sidebar-mobile__item
    v-icon(color="#000") mdi-calendar-month

  div(
    @mouseover="showSidebar('candidates')"
    @mouseleave="hideSidebar('candidates')"
    :class="{'sidebar__menu_active': isCandidatesPage, 'sidebar__menu_sidebar': sidebar.candidates}"
    v-click-outside="hideSidebarNoDelayFunc('candidates')"
  ).sidebar-mobile__item
    v-tooltip(right content-class="sidebar-tooltip")
      template(v-slot:activator="{on}")
        router-link.sidebar-menu(
          v-on="on"
          data-test="sidebar-candidates"
          :to="{name: $ROUTER_NAMES.CANDIDATES}"
        )
        
          ico-candidates
      span Candidates
  router-link(
     v-show="currentPerms.has(CRM_PERMS.COURSES_LEADERS)"
    label="Course leaders"
    :to="{name: $ROUTER_NAMES.COURSE_LEADERS}"
  ).sidebar-mobile__item
    ico-leaders
  .sidebar-mobile__item
    user-menu
      template(#activator="{on, toggle}")
        v-icon(v-on="on" color="#000" @click="toggle") mdi-cog
</template>

<script>
import { CANDIDATES_TABS } from '@/app/admin/modules/candidates/core/candidates-const'
import { CRM_PERMS } from '@/util/const'
/**
  course-managment-menu(
    v-show="currentPerms.has(CRM_PERMS.COURSE_MANAGEMENT)"
    :nameToGo="$ROUTER_NAMES.COURSE_MANAGEMENT"
    :sidebarActive="sidebar.listManagement"
    @mouseover="showSidebar('listManagement')"
    @mouseleave="hideSidebar('listManagement')"
    @click:item="hideSidebarNoDelay('listManagement')"
    v-click-outside="hideSidebarNoDelayFunc('listManagement')"
    mobile
  )
**/
export default {
  inject: ['perms'],

  computed: {
    currentPerms() {
      if (!this.perms) return {
        has: () => false
      }
      return this.perms
    },

    isCandidatesPage() {
      return CANDIDATES_TABS.includes(this.$route.name)
    },
  },

  data: () => ({
    CRM_PERMS,
    sidebar: {
      courses: false,
      candidates: false,
      listManagement: false
    },
    sidebarTimeout: null,
  }),

  methods: {
    showSidebar(name) {
      this.clearSidebarShowing()
      if (this.sidebarTimeout) {
        clearTimeout(this.sidebarTimeout)
        this.sidebarTimeout = null
      }
      this.sidebar[name] = true
    },

    clearSidebarShowing() {
      for (let name in this.sidebar) {
        this.sidebar[name] = false
      }
    },

    hideSidebar(name) {
      this.sidebarTimeout = setTimeout(() => {
        this.sidebar[name] = false
      }, 1000);
    },

    hideSidebarNoDelay(name) {
      this.sidebar[name] = false
    },

    hideSidebarNoDelayFunc(name) {
      const hide = () => this.sidebar[name] = false
      return hide
    }
  },

  components: {
    icoPlan: () => import('@/assets/img/ui/crm/IcoPlan.vue'),
    icoCandidates: () => import('@/assets/img/ui/crm/IcoCandidatesBlack.vue'),
    icoLeaders: () => import('@/assets/img/ui/crm/IcoLeadersBlack.vue'),
    courseManagmentMenu: () => import('@/app/admin/components/CMMenu.vue'),
    userMenu: () => import("@/components/widget/UserSettings.vue"),

  }
}
</script>

<style lang="scss" scoped>
.sidebar-mobile {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 56px;
  background: #FFFFFF;
  box-shadow: 0 0 24px rgba(22, 22, 22, 0.16);
  &__item {
    display: inline-flex;
    align-items: center;
    width: 32px;
    height: 32px;
    color: #000000;
    text-decoration: none;
  }
}

</style>