import ROUTE_NAMES from './router-const';
import AuthLayout from '../Auth-layout.vue';
import CRMRouter from '../modules/crm/core/CRM-router';
import LandingRouter from '../modules/landing/core/Landing-router';

export default {
  path: '/auth',
  component: AuthLayout,
  children: [
    {
      path: '',
      name: ROUTE_NAMES.LOGIN,
      component: () => import('../LoginPage.vue'),
    },
    ...CRMRouter,
    ...LandingRouter,
  ]
};