<template lang="pug">
  v-app
    router-view
    dialog-bus
    snackbar-bus
    notifications(animation-type="velocity" width="300px")
</template>

<script>
import DialogBus from "@/components/global/DialogGlobal.vue"
import SnackbarBus from "@/components/global/SnackbarGlobal.vue"

export default {

  created() {
    const addMaximumScaleToMetaViewport = () => {
      const el = document.querySelector('meta[name=viewport]');

      if (el !== null) {
        let content = el.getAttribute('content');
        let re = /maximum\-scale=[0-9\.]+/g;

        if (re.test(content)) {
          content = content.replace(re, 'maximum-scale=1.0');
        } else {
          content = [content, 'maximum-scale=1.0'].join(', ')
        }

        el.setAttribute('content', content);
      }
    };

    const disableIosTextFieldZoom = addMaximumScaleToMetaViewport;

    // https://stackoverflow.com/questions/9038625/detect-if-device-is-ios/9039885#9039885
    const checkIsIOS = () =>
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    if (checkIsIOS()) {
      disableIosTextFieldZoom();
    }
  },
  name: 'App',
  components: {
    DialogBus,
    SnackbarBus
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/index";
@import "~material-design-icons-iconfont/dist/material-design-icons.css";
</style>
