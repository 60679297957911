import rules from "@/util/form-validation-rules"

export class LabelBase {
  constructor(item = {}) {
    this.ID = item.id
    this.name = item.name
    this.color = item.hex_color_code
  }
}
  
export default class LabelModel extends LabelBase {
  constructor(item) {
    super(item)
    this.apiAttributes = {
      name: 'name'
    }
  }

  validate(validator) {
    validator.resetFields()
    this.validateName(validator)
    return validator.isValid()
  }

  validateName(validator) {
    if(rules.required(this.name)) return
    validator.invalidField('name', 'Name is required')
  }

  reset() {
    this.ID = null
    this.name = null
    this.color = null
  }

  getApiData() {
    return {
      name: this.name,
      hex_color_code: this.color
    }
  }
}