import auth from "@/services/CRMinitAuth"

const current = query =>
  auth.get(`${process.env.VUE_APP_CRM_API}status/`, {
    params: query
  });

const logout = () =>
  auth.post(`${process.env.VUE_APP_CRM_API}auth/logout/`);

export default {
  current,
  logout
}