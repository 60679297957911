import cbrUSerAPI  from "@/services/api/api-cbr-user"
export class CrmUser {
  constructor(item = {}) {
    this.username = item.username
    this.ID = item.id
    this.products = item.products
    this.forExtendedExams = item.for_extended_exams
    this.courseLocations = item.cities
  }
}

export default {
  namespaced: true,

  state: () => ({
    items: null,
    default: null
  }),

  getters: {
    items: state => state.items,
    default: state => state.default,
    getNameByID: state =>
      ID => {
        if(!state.items) return null
        let currentItem = state.items.find(item => item.ID === ID)
        if(!currentItem || currentItem < 0) return null
        return currentItem.username
      }
  },

  mutations: {
    set(state, items) {
      state.items = items
    },

    setDefault(state, item) {
      state.default = new CrmUser(item)
    }
  },

  actions: {
    async list({state, commit}, params) {
      try {
        if(params && params.cache && state.items) return state.items
        let res = await cbrUSerAPI.list()
        res.all = res.all.map(user => new CrmUser(user))
        commit('setDefault', res.default)
        commit('set', res.all)
        return res.all
      } catch (error) {
        console.log(error)
        return Promise.reject(error)
      }
    },
  }
}