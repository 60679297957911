import CRMauth from '@/services/CRMinitAuth';

const onlinePlansList = query =>
  CRMauth.get(`${process.env.VUE_APP_CRM_API}common/online_plans/`, {
    params: query
  });


export default {
  onlinePlansList
};
