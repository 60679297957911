export const REQUIRED = value => !!value || "Required.";

export const REQUIRED_OBJECT = value => !!value && JSON.stringify(value) !== '{}' || "Required.";

export const EMAIL = value => {
  const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return pattern.test(value) || "Invalid e-mail.";
};

export const DATE = value => {
  const pattern = /^([0-2][0-9]|(3)[0-1])(-)(((0)[0-9])|((1)[0-2]))(-)\d{4}$/;
  return pattern.test(value) || "Date should have format DD-MM-YYYY";
};

export const DATE_INDIAN = value => {
  const pattern = /^(((0*[1-9]|[12][0-9]|3[01])([-])(0*[13578]|10|12)([-])(\d{4}))|((0*[1-9]|[12][0-9]|30)([-])(0*[469]|11)([-])(\d{4}))|((0*[1-9]|1[0-9]|2[0-8])([-])(02|2)([-])(\d{4}))|((29)(\.|-|\/)(02|2)([-])([02468][048]00))|((29)([-])(02|2)([-])([13579][26]00))|((29)([-])(02|2)([-])([0-9][0-9][0][48]))|((29)([-])(02|2)([-])([0-9][0-9][2468][048]))|((29)([-])(02|2)([-])([0-9][0-9][13579][26])))/;
  return pattern.test(value) || "Date should have format DD-MM-YYYY or D-M-YYYY";
};

export const DATETIME = value => {
  const pattern = /^([0-2][0-9]|(3)[0-1])(-)(((0)[0-9])|((1)[0-2]))(-)\d{4}(\s)([0-2][0-9])(:)([0-5][0-9])$/;
  return pattern.test(value) || "Date should have format 21-04-2020 14:00";
};

export const TIME = value => {
  const pattern = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
  return pattern.test(value) || "Time should have format 14:00";
};

export const SAME_AS = (operand) => (value) => value === operand || 'Fields should be the same';


