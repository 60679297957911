import {convertToDefaultDateFormat, truncateString, DDMMYYYYFormatting, convertToDefaultDateHourFormat, convertToDefaultDateFormatDDDInBeginning, convertToDefaultDateFormatDDD, dateFormating, getDOWDutchDDD, getDOWDutch, DeleteMS as _DeleteMS} from "@/util"

export function normalizeListWithComma (list) {
  if(!list || !list.length) return ''
  return list.map(item => item).join(", ")
}

export function booleanToYN(bool) {
  if (bool) return 'Yes';
  return 'No';
}

export function getMonthDutch(value, type = 'short') {
  if(!value) return
  return new Date(value).toLocaleString('nl', {month: type})
}

export function getDayDutch(value) {
  if(!value) return
  return new Date(value).toLocaleString('nl', {day: 'numeric'})
}

export function formattedDate(value) {
  if(!value) return
  return convertToDefaultDateFormat(value)
}

export function formattedDatetime(value) {
  if(!value) return
  return convertToDefaultDateHourFormat(value)
}

export function formattedDateWithDDD(value) {
  if(!value) return
  return convertToDefaultDateFormatDDD(value)
}

export function formattedDateWithDDDInBeginning(value) {
  if(!value) return
  return convertToDefaultDateFormatDDDInBeginning(value)
}

export function formattedDefaultDateWithDDD(value) {
  if(!value) return
  return convertToDefaultDateFormatDDD(dateFormating(value).join('-'))
}

export function DOWDutchDDD(value) {
  if(!value) return

  return getDOWDutchDDD(
    new Date(
      dateFormating(value).join('-')
    ).getDay()
  )
}

export function DOWDutchWithFormatting(value) {
  return DOWDutch(dateFormating(value).join('-'))
}

export function DOWDutch(value) {
  if(!value) return

  return getDOWDutch(
    new Date(value).getDay()
  )
}

export function onlyFirstLetter(value) {
  if(!value) return
  return String(value)[0];
}

export function clipString (val, quant = 30) {
  return truncateString(val, quant)
}

export function DeleteMS(val) {
  return _DeleteMS(val)
}
