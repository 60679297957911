import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import User from './user';
import CbrUser from './crm/cbrUser';
import crmExamLocation from './crm/crmExamLocation';
import crmExamAnswers from './crm/crmExamAnswers';
import CrmCourseCities from './crm/crmCourseCities';
import crmCourseLeaders from './crm/crmCourseLeaders';
import CrmProductNames from './crm/crmProductNames';
import crmMeetingsSpaces from './crm/crmMeetingsSpaces';
import crmJourneys from './crm/crmJourneys';
import LandingCities from './landing/cities';
import LandingReviews from './landing/reviewFilters';
import CrmLabels from './crm/crmLabels';
import EmailTemplates from './crm/crmEmailTemplates';
import SlotsType from '@/app/admin/modules/slots/core/slots-type-store';
import CMStore from '@/app/admin/modules/course_managment/core/cm-store';
import CourseDayStore from '@/app/admin/modules/course_day/core/courseDay-store';
import crmCandidateColors from './crm/crmCandidateColors';
import allCourses from './crm/allCourses';
import storage from './storage';
import results from '@/app/admin/modules/results/core/results-store';
import sessions from './crm/sessions';
import packages from './crm/packages';
import drivingSchools from './crm/crmDrivingSchools';
import featureFlags from './crm/featureFlags';

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user: User,
    crmCandidateColors: crmCandidateColors,
    cbrUser: CbrUser,
    crmExamLocation: crmExamLocation,
    crmExamAnswers: crmExamAnswers,
    crmCourseCities: CrmCourseCities,
    crmCourseLeaders: crmCourseLeaders,
    crmProductNames: CrmProductNames,
    crmMeetingsSpaces: crmMeetingsSpaces,
    crmJourneys: crmJourneys,
    labels: CrmLabels,
    landingCities: LandingCities,
    landingReviews: LandingReviews,
    slotsType: SlotsType,
    emailTemplates: EmailTemplates,
    cm: CMStore,
    courseDay: CourseDayStore,
    allCourses: allCourses,
    crmDrivingSchools: drivingSchools,
    packages,
    storage,
    results,
    sessions,
    featureFlags,
  }
});
