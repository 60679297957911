export class CourseLeader {
  
  constructor(item = {}) {
    this.ID = item[CL_ARGS.ID];
    this.name = item[CL_ARGS.NAME];
    this.isArchived = item[CL_ARGS.IS_ARCHIVED];
  }

  getApiData() {
    return {
      [CL_ARGS.ID]: this.ID,
      [CL_ARGS.NAME]: this.name,
      [CL_ARGS.IS_ARCHIVED]: this.isArchived,
    };
  }
}

export const CL_ARGS = {
  ID: 'id',
  NAME: 'name',
  IS_ARCHIVED: 'is_archived'
};
