import ROUTE_NAMES from '@/app/admin/router/router-const'
import { CRM_PERMS } from '@/util/const'
export default [
  {
    path: 'course_management',
    name: ROUTE_NAMES.COURSE_MANAGEMENT,
    component: () => import('../CMPage.vue'),
  },
  {
    path: 'courses',
    name: ROUTE_NAMES.COURSES,
    component: () => import('@/app/admin/modules/course_day/CoursePage.vue'),
  },
  {
    path: 'course_management_all',
    name: ROUTE_NAMES.MASTER_PLANNING,
    component: () => import('../CMAllPage.vue'),
    meta: {
      perms: CRM_PERMS.COURSE_MANAGEMENT
    },
  }
]
