export default class cityModel {
  constructor(item = {}) {
    this.ID = item.id;
    this.name = item.name;
    this.code = item.code;
  }
}

export class CityWithAdditionalInfoModel extends cityModel {
  constructor(item = {}) {
    super(item);
    this.city_exams = item.city_exams;
    this.top_cbr_reserve_queue_count = item.top_cbr_reserve_queue_count;
    this.top_cbr_exchange_queue_count = item.top_cbr_exchange_queue_count;
  }
}
