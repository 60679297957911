<template lang="pug">
    v-card(data-test="dialog-err")
        v-card-title(dark).headline.error.white--text {{ title }}
        v-card-text.mt-3 {{ msg }}
        v-card-actions
            v-spacer
            v-btn(color="primary" text @click="closeModal" data-test='dialog-err-ok') ok

</template>

<script>
export default {
  props: {
    title: String,
    msg: [String, Object]
  },
  methods: {
    closeModal() {
      this.$emit('onClose')
    }
  }
}
</script>