import api from "@/services/api/api-colors"
import { CandidateColor } from "./models/candidateColorModel"

export default class CandidatesColorService {
  constructor() {
    this.api = api
  }

  async list() {
    try {
      const res = await this.api.colorList();
      return res.map(item => new CandidateColor(item))
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async create(data) {
    try  {
      const res = await this.api.createColor(new CandidateColor(data));
      return new CandidateColor(res);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async update(data) {
    try  {
      const res = await this.api.updateColor(data.id, new CandidateColor(data));
      return new CandidateColor(res);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async delete(ID) {
    try  {
      const res = await this.api.deleteColor(ID);
    } catch (err) {
      return Promise.reject(err);
    }
  }
}