export default class Selector {
  constructor() {
    this.activeElement = null
  }
  
  set(activeElement) {
    if(this.activeElement) this.activeElement.unselect()
    this.activeElement = activeElement
    this.activeElement.select()
  }
  
  reset() {
    if(this.activeElement) this.activeElement.unselect()
    this.activeElement = null
  }
}
  
export class SelectBase {
  constructor() {
    this.active = false
  }
  
  unselect() {
    this.active = false
  }
  
  select() {
    this.active = true
  }
}
  