import CRMauth from "@/services/CRMinitAuth"

const list = query =>
  CRMauth.get(`${process.env.VUE_APP_CRM_API}candidate_emails/email_labels/`);

const listCustom = query =>
  CRMauth.get(`${process.env.VUE_APP_CRM_API}candidate_emails/`);

const post = data => 
  CRMauth.post(`${process.env.VUE_APP_CRM_API}candidate_emails/`, data)

const remove = (ID,data) => 
  CRMauth.delete(`${process.env.VUE_APP_CRM_API}candidate_emails/${ID}/`, data)

const put = (ID, data) => 
  CRMauth.put(`${process.env.VUE_APP_CRM_API}candidate_emails/${ID}/`, data)

export default {
  list,
  post,
  listCustom,
  remove,
  put
}