import api from "@/services/api/api-emailTemplates"
import {CustomEmailTemplateModel, SystemEmailTemplateModel, EMAIL_TEMPLATE_ARGS} from "@/app/admin/modules/candidates/core/models/CandidatesEmailTemplateModel"

export default {
  namespaced: true,

  state: () => ({
    items: null,
    itemsCustom: null
  }),

  getters: {
    items: state => state.items,
    itemsCustom: state => state.itemsCustom
  },

  mutations: {
    set(state, items) {
      state.items = items.map( item => new SystemEmailTemplateModel(item))
    },

    setCustom(state, items) {
      state.itemsCustom = items.map( item => new CustomEmailTemplateModel(item))
    },

    add(state, item) {
      let _item = new CustomEmailTemplateModel(item)
      state.itemsCustom.push(_item)
    },

    update(state, {ID, data}) {
      let deletedItemIndex = state.itemsCustom.findIndex(item => item.ID === ID)
      if(deletedItemIndex >= 0) state.itemsCustom[deletedItemIndex].merge(data)
    },

    remove(state, ID) {
      let deletedItemIndex = state.itemsCustom.findIndex(item => item.ID === ID)
      if(deletedItemIndex >= 0) state.itemsCustom.splice(deletedItemIndex, 1)
    }
  },

  actions: {
    async list({state, commit}, params) {
      try {
        if(params && params.cache && state.items) return state.items
        let res = await api.list()
        commit('set', res)
        return res
      } catch (error) {
        return Promise.reject(error)
      }
    },
    
    async listCustom({state, commit}, params) {
      try {
        if(params && params.cache && state.items) return state.items
        let res = await api.listCustom()
        commit('setCustom', res)
        return res
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async post({state, commit}, data) {
      try {
        let res = await api.post(data)
        commit('add', res)
        return state.itemsCustom[state.itemsCustom.length - 1]
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async put({commit}, {ID, data}) {
      try {
        let res = await api.put(ID, data)
        commit('update', {ID, data})
        return res
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async remove({commit}, ID) {
      try {
        let res = await api.remove(ID)
        commit('remove', ID)
        return res
      } catch (error) {
        return Promise.reject(error)
      }
    },
  }
}