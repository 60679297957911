<template lang="pug">
  aside.sidebar
    .sidebar__wrapper
      img(:src="require('@/assets/img/ui/logo.svg')").sidebar__logo
      sidebar-menu(
        v-show="currentPerms.has(CRM_PERMS.COURSES) || currentPerms.has(CRM_PERMS.COURSES_LEADERS)"
        tooltipText="Courses"
        :to="{name: $ROUTER_NAMES.COURSES}"
        :class="{'sidebar__menu_active': $route.name === $ROUTER_NAMES.COURSES}"
      )
        template(v-slot:icon)
          ico-plan.sidebar-menu__icon

      course-managment-menu(
        v-show="currentPerms.has(CRM_PERMS.COURSE_MANAGEMENT)"
        :nameToGo="$ROUTER_NAMES.COURSE_MANAGEMENT"
        :sidebarActive="sidebar.listManagement"
        @mouseover="showSidebar('listManagement')"
        @mouseleave="hideSidebar('listManagement')"
        @click:item="hideSidebarNoDelay('listManagement')"
        v-click-outside="hideSidebarNoDelayFunc('listManagement')"
      )

      sidebar-menu(
        v-show="currentPerms.has(CRM_PERMS.COURSE_MANAGEMENT)"
        tooltipText="Master Planning"
        :to="{name: $ROUTER_NAMES.MASTER_PLANNING}"
        :class="{'sidebar__menu_active': $route.name === $ROUTER_NAMES.MASTER_PLANNING}"
      )
        template(v-slot:icon)
          ico-plan.sidebar-menu__icon

      sidebar-menu(
        tooltipText="Cursuslijsten"
        :to="{name: $ROUTER_NAMES.RESULTS}"
        :class="{'sidebar__menu_active': $route.name === $ROUTER_NAMES.RESULTS}"
        v-show="currentPerms.has(CRM_PERMS.RESULTS)"
      )
        template(v-slot:icon)
          ico-results.sidebar-menu__icon

      div(
        @mouseover="showSidebar('candidates')"
        @mouseleave="hideSidebar('candidates')"
        :class="{'sidebar__menu_active': isCandidatesPage, 'sidebar__menu_sidebar': sidebar.candidates}"
        v-click-outside="hideSidebarNoDelayFunc('candidates')"
      ).sidebar__menu
        v-tooltip(right content-class="sidebar-tooltip")
          template(v-slot:activator="{on}")
            router-link.sidebar-menu(
              v-on="on"
              data-test="sidebar-candidates"
              :to="{name: $ROUTER_NAMES.CANDIDATES}"
            )
              .sidebar-menu__wrapper
                ico-candidates.sidebar-menu__icon
          span Candidates
        .sidebar__sub(
          @click="hideSidebarNoDelay('candidates')"
          @mouseover="showSidebar('candidates')"
          @mouseleave="hideSidebar('candidates')"
        )
          portal-target(name="candidate-location-menu")
            sidebar-item(:to="{name: $ROUTER_NAMES.CANDIDATES}" text="all")
            sidebar-item(
              v-for="location in crmCourseLocation"
              :key="location.ID"
              :to="{name: $ROUTER_NAMES.CANDIDATES, query: {city: [location.ID]}}"
              :text="location.name"
              @click="hideSidebarNoDelay('listManagement')"
              link
            )

      //
      sidebar-menu-item(
        v-show="currentPerms.has(CRM_PERMS.SLOTS)"
        label="Slots"
        :to="{name: $ROUTER_NAMES.SLOTS}"
      )
        template(#icon)
          ico-slots.sidebar-menu__icon

      sidebar-menu-item(
        v-show="currentPerms.has(CRM_PERMS.CBR_UPDATES)"
        label="CBR updates"
        :to="{name: $ROUTER_NAMES.CBR_UPDATES}"
      )
        template(#icon)
          ico-slots-cbr.sidebar-menu__icon

      sidebar-menu-item(
        v-show="currentPerms.has(CRM_PERMS.CBR_ACCOUNTS)"
        label="CBR accounts"
        :to="{name: $ROUTER_NAMES.CBR_ACC}"
      )
        template(v-slot:icon)
          ico-cbr.sidebar-menu__icon

      sidebar-menu-item(
        v-show="currentPerms.has(CRM_PERMS.COURSES_LEADERS)"
        label="Course leaders"
        :to="{name: $ROUTER_NAMES.COURSE_LEADERS}"
      )
        template(v-slot:icon)
          ico-leaders.sidebar-menu__icon

      sidebar-menu-item(
        label="Driving schools"
        :to="{name: $ROUTER_NAMES.DRIVING_SCHOOLS}"
        :active="isDrivingSchoolPage"
        v-show="currentPerms.has(CRM_PERMS.DRIVING_SCHOOLS)"
      )
        template(v-slot:icon)
          ico-driving-school.sidebar-menu__icon

      sidebar-menu-item(
        v-show="currentPerms.has(CRM_PERMS.APPLICATIONS_TASK_LIST)"
        label="Applications task list"
        :to="{name: $ROUTER_NAMES.TASK_APPLICATIONS}"
      )
        template(v-slot:icon)
          ico-task-app-ico.sidebar-menu__icon

      sidebar-menu-item(
        v-show="currentPerms.has(CRM_PERMS.REAPPLY_TASK_LIST)"
        label="Reapply task list"
        :to="{name: $ROUTER_NAMES.REAPPLY_APPLICATIONS}"
      )
        template(v-slot:icon)
          ico-reapply-app-ico.sidebar-menu__icon

      sidebar-menu-item(
        v-show="currentPerms.has(CRM_PERMS.FEEDBACK)"
        label="Feedback task list"
        :to="{name: $ROUTER_NAMES.FEEDBACK}"
      )
        template(v-slot:icon)
          ico-feedback-app-ico.sidebar-menu__icon

      //
      user-menu
</template>

<script>
import { mapGetters } from 'vuex';
import vClickOutside from 'v-click-outside'
import { CRM_PERMS } from '@/util/const'
import { DRIVING_SCHOOL_ROUTES } from '@/app/admin/modules/driving_schools/core/drivingSchool-const';
import { CANDIDATES_TABS } from "@/app/admin/modules/candidates/core/candidates-const";

export default  {
  name: 'Sidebar',

  inject: ['perms'],

  directives: {
    clickOutside: vClickOutside.directive
  },

  computed: {
    ...mapGetters({
      crmCourseLocation: 'crmCourseCities/items'
    }),

    currentPerms() {
      if (!this.perms) return {
        has: () => false
      }
      return this.perms
    },

    isCandidatesPage() {
      return CANDIDATES_TABS.includes(this.$route.name)
    },

    isDrivingSchoolPage() {
      return DRIVING_SCHOOL_ROUTES.includes(this.$route.name)
    }
  },

  data: () => ({
    sidebar: {
      courses: false,
      candidates: false,
      listManagement: false
    },
    sidebarTimeout: null,
    CRM_PERMS
  }),

  methods: {
    showSidebar(name) {
      this.clearSidebarShowing()
      if (this.sidebarTimeout) {
        clearTimeout(this.sidebarTimeout)
        this.sidebarTimeout = null
      }
      this.sidebar[name] = true
    },

    clearSidebarShowing() {
      for (let name in this.sidebar) {
        this.sidebar[name] = false
      }
    },

    hideSidebar(name) {
      this.sidebarTimeout = setTimeout(() => {
        this.sidebar[name] = false
      }, 1000);
    },

    hideSidebarNoDelay(name) {
      this.sidebar[name] = false
    },

    hideSidebarNoDelayFunc(name) {
      const hide = () => this.sidebar[name] = false
      return hide
    }
  },

  components: {
    userMenu: () => import("@/components/widget/UserSettings.vue"),
    courseManagmentMenu: () => import('@/app/admin/components/CMMenu.vue'),
    sidebarMenu: () => import('@/components/global/sidebar/SidebarMenu.vue'),
    sidebarItem: () => import('@/components/global/sidebar/SidebarItem.vue'),
    SidebarMenuItem: () => import('@/components/global/sidebar/SidebarMenuItem.vue'),

    // icons
    icoDrivingSchool: () => import('@/assets/img/ui/crm/IcoDrivingSchool.vue'),
    icoCbrAvailability: () => import('@/assets/img/ui/crm/IcoCbrAvailability.vue'),
    icoCandidates: () => import('@/assets/img/ui/crm/IcoCandidates.vue'),
    icoPlan: () => import('@/assets/img/ui/crm/IcoPlan.vue'),
    icoResults: () => import('@/assets/img/ui/crm/IcoResults.vue'),
    icoSlots: () => import('@/assets/img/ui/crm/IcoSlots.vue'),
    icoSlotsCbr: () => import('@/assets/img/ui/crm/IcoSlotsCBR.vue'),
    icoCbr: () => import('@/assets/img/ui/crm/IcoCbr.vue'),
    icoLeaders: () => import('@/assets/img/ui/crm/IcoLeaders.vue'),
    icoTaskAppIco: () => import('@/assets/img/ui/crm/IcoApplications.vue'),
    icoReapplyAppIco: () => import('@/assets/img/ui/crm/IcoReapplyApplications.vue'),
    icoFeedbackAppIco: () => import('@/assets/img/ui/crm/IcoFeedbacks.vue'),
    icoSettings: () => import('@/assets/img/ui/crm/IcoSettings.vue'),
  }
}
</script>