import CRMauth from "@/services/CRMinitAuth";

const endPoint = 'common';

const list = query =>
  CRMauth.get(`${process.env.VUE_APP_CRM_API}${endPoint}/course_leaders/`, {
    params: query
  });

const listSessions = query =>
  CRMauth.get(`${process.env.VUE_APP_CRM_API}${endPoint}/course_sessions/`, {
    params: query
  });

const statsListByLeader = query =>
  CRMauth.get(`${process.env.VUE_APP_CRM_API}${endPoint}/course_leaders_statistic/by_leader/`, {
    params: query
  });

const statsListByLeaderOnCourse = query =>
  CRMauth.get(`${process.env.VUE_APP_CRM_API}${endPoint}/course_leaders_statistic/by_leader_on_course/`, {
    params: query
  });

const statsListByCourse = query =>
  CRMauth.get(`${process.env.VUE_APP_CRM_API}${endPoint}/course_leaders_statistic/by_course/`, {
    params: query
  });

const getCSVReportByLeaderOnCourse = query =>
  CRMauth.get(`${process.env.VUE_APP_CRM_API}${endPoint}/course_leaders_statistic/csv_by_leader_on_course/`, {
    params: query
  });

const getCSVReportByLeader = query =>
  CRMauth.get(`${process.env.VUE_APP_CRM_API}${endPoint}/course_leaders_statistic/csv_by_leader/`, {
    params: query
  });

const create = data =>
  CRMauth.post(`${process.env.VUE_APP_CRM_API}${endPoint}/course_leaders/`, data);

const update = data =>
  CRMauth.patch(`${process.env.VUE_APP_CRM_API}${endPoint}/course_leaders/${data.id}/`, data);

export default {
  list,
  create,
  update,
  listSessions,
  statsListByLeader,
  statsListByLeaderOnCourse,
  statsListByCourse,
  getCSVReportByLeaderOnCourse,
  getCSVReportByLeader
};

