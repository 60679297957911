import { convertToDefaultDateFormat, dateFormating } from '@/util';

export const CM_EXAM_CONFIG = {
  ID: 'id',
  EXAM_DATE: 'exam_date',
  EXAM_DATE_FROM: 'exam_date_from',
  EXAM_DATE_TO: 'exam_date_to',
  EXAM_DATE_FUTURE_DAYS: 'exam_date_future_days',
  EXAM_TIME_FROM: 'exam_time_from',
  EXAM_TIME_TO: 'exam_time_to',
  LOCATION: 'location',
  LICENSE_TYPE: 'license_type',
  CANDIDATES_COUNT: 'candidates_count',
  SLOTS_COUNT: 'slots_count',
  MIJN_CBR_QUEUE_COUNT: 'mijn_cbr_queue_count',
  TOP_CBR_RESERVE_QUEUE_COUNT: 'top_cbr_reserve_queue_count',
  TOP_CBR_EXCHANGE_QUEUE_COUNT: 'top_cbr_exchange_queue_count',
  RANGE_MODE: 'rangeMode'
};

export default class CMExamConfig {
  constructor(item = {}) {
    this.ID = item.id;
    this.date = item[CM_EXAM_CONFIG.EXAM_DATE];
    this.dateFrom = item[CM_EXAM_CONFIG.EXAM_DATE_FROM];
    this.dateTo = item[CM_EXAM_CONFIG.EXAM_DATE_TO];
    this.exam_date_future_days = item[CM_EXAM_CONFIG.EXAM_DATE_FUTURE_DAYS];
    this.timeFrom = item[CM_EXAM_CONFIG.EXAM_TIME_FROM];
    this.timeTo = item[CM_EXAM_CONFIG.EXAM_TIME_TO];
    this.location = item[CM_EXAM_CONFIG.LOCATION];
    this.licenseType = item[CM_EXAM_CONFIG.LICENSE_TYPE];
    this.candidates_count = item[CM_EXAM_CONFIG.CANDIDATES_COUNT];
    this.slots_count = item[CM_EXAM_CONFIG.SLOTS_COUNT];
    this.mijn_cbr_queue_count = item[CM_EXAM_CONFIG.MIJN_CBR_QUEUE_COUNT];
    this.top_cbr_reserve_queue_count = item[CM_EXAM_CONFIG.TOP_CBR_RESERVE_QUEUE_COUNT];
    this.top_cbr_exchange_queue_count = item[CM_EXAM_CONFIG.TOP_CBR_EXCHANGE_QUEUE_COUNT];
    this.rangeMode = item[CM_EXAM_CONFIG.RANGE_MODE] || null;
  }
}

export class CMOverviewExamConfigDecorator {
  constructor(examConfig) {
    this.examConfig = examConfig;
  }

  convert() {
    return {
      ...this.examConfig,
      formatted_date_from: convertToDefaultDateFormat(this.examConfig.dateFrom) || null,
      formatted_date_to: convertToDefaultDateFormat(this.examConfig.dateTo) || null,
      formatted_date: convertToDefaultDateFormat(this.examConfig.date) || null,
      formatted_timeFrom: this.examConfig.timeFrom.slice(0, this.examConfig.timeFrom.lastIndexOf(':')),
      formatted_timeTo: this.examConfig.timeTo.slice(0, this.examConfig.timeTo.lastIndexOf(':'))
    };
  }
}

export class CMExamHourFormatter {
  constructor(item = {}) {
    this.item = item
  }

  execute() {
    let item = {...this.item}
    item[CM_EXAM_CONFIG.EXAM_TIME_FROM] = item[CM_EXAM_CONFIG.EXAM_TIME_FROM].split(':');
    item[CM_EXAM_CONFIG.EXAM_TIME_FROM].pop();
    item[CM_EXAM_CONFIG.EXAM_TIME_FROM] = item[CM_EXAM_CONFIG.EXAM_TIME_FROM].join(':');
    item[CM_EXAM_CONFIG.EXAM_TIME_TO] = item[CM_EXAM_CONFIG.EXAM_TIME_TO].split(':');
    item[CM_EXAM_CONFIG.EXAM_TIME_TO].pop();
    item[CM_EXAM_CONFIG.EXAM_TIME_TO] = item[CM_EXAM_CONFIG.EXAM_TIME_TO].join(':');
    return item
  }
}

export class CMExamConfigApiData {
  constructor(data) {
    this.data = data;
  }

  convert() {
    return {
      [CM_EXAM_CONFIG.EXAM_DATE]: dateFormating(this.data.date).join('-'),
      [CM_EXAM_CONFIG.EXAM_TIME_FROM]: this.data.timeFrom,
      [CM_EXAM_CONFIG.EXAM_TIME_TO]: this.data.timeTo,
      [CM_EXAM_CONFIG.LOCATION]: this.data.location,
      [CM_EXAM_CONFIG.LICENSE_TYPE]: this.data.licenseType,
    };
  }

  convertExam() {
    if (this.data.exam_date_future_days && !this.data.rangeMode) {
      return {
        [CM_EXAM_CONFIG.EXAM_DATE_FROM]: null,
        [CM_EXAM_CONFIG.EXAM_DATE_TO]: null,
        [CM_EXAM_CONFIG.EXAM_DATE_FUTURE_DAYS]: this.data.exam_date_future_days,
        [CM_EXAM_CONFIG.EXAM_DATE]: null,

        [CM_EXAM_CONFIG.EXAM_TIME_FROM]: this.data.timeFrom,
        [CM_EXAM_CONFIG.EXAM_TIME_TO]: this.data.timeTo,
        [CM_EXAM_CONFIG.LOCATION]: this.data.location,
        [CM_EXAM_CONFIG.LICENSE_TYPE]: this.data.licenseType
      };
    } else return {
      [CM_EXAM_CONFIG.EXAM_DATE_FROM]: dateFormating(this.data.dateFrom).join('-'),
      [CM_EXAM_CONFIG.EXAM_DATE_TO]: dateFormating(this.data.dateTo).join('-'),
      [CM_EXAM_CONFIG.EXAM_DATE_FUTURE_DAYS]: null,
      [CM_EXAM_CONFIG.EXAM_DATE]: null,

      [CM_EXAM_CONFIG.EXAM_TIME_FROM]: this.data.timeFrom,
      [CM_EXAM_CONFIG.EXAM_TIME_TO]: this.data.timeTo,
      [CM_EXAM_CONFIG.LOCATION]: this.data.location,
      [CM_EXAM_CONFIG.LICENSE_TYPE]: this.data.licenseType
    };
  }
}
