import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import RefreshIcon from "@/assets/img/ui/RefreshIcon.vue"
import SearchIcon from "@/assets/img/ui/SearchIcon.vue"

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#4e7fed',
        success: '#52d18b',
        drawer: '#2c2d33',
        warnig: '#fb8c00',
        error: '#ff5252',
        newPrimary: '#007aff'
      }
    }
  },
  icons: {
    iconfont: 'mdiSvg',
    values: {
      edit: 'mdi-table-edit',
      delete: 'mdi-delete-off-outline',
      closeOutline: 'mdi-close-circle-outline',
      plusCircle: 'mdi-plus-circle-outline',
      plus: 'mdi-plus',
      clock: 'mdi-timer',
      hamburger: 'mdi-menu',
      search: 'mdi-magnify',
      location: 'mdi-map-marker',
      checkboxCircle: 'mdi-checkbox-marked-circle-outline',
      calendar: 'mdi-calendar-today',
      clockOutline: 'mdi-clock-time-five-outline',
      account: 'mdi-account',
      voice: 'mdi-account-voice',
      refresh: {
        component: RefreshIcon
      },
      searchNew: {
        component: SearchIcon
      }
    }
  },
});
