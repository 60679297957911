import { SelectBase } from "@/util/selector"

export const EMAIL_TEMPLATE_ARGS = {
  ID: 'id',
  LABEL: 'label',
  SUBJECT: 'subject',
  CONTENT: 'content'
}

export default class CandidateTemplateModel {
  constructor(item = {
    [EMAIL_TEMPLATE_ARGS.LABEL]: ''
  }) {
    this.ID = item[EMAIL_TEMPLATE_ARGS.ID]
    this.label = item[EMAIL_TEMPLATE_ARGS.LABEL]
    this.subject = item[EMAIL_TEMPLATE_ARGS.SUBJECT]
  }
}

export class SystemEmailTemplateModel extends CandidateTemplateModel {
  constructor(item) {
    super({
      [EMAIL_TEMPLATE_ARGS.LABEL]: item
    })
    this.readonly = true
  }
}

export class CustomEmailTemplateModel extends CandidateTemplateModel {
  constructor(item = {}) {
    super(item)
    this.content = item[EMAIL_TEMPLATE_ARGS.CONTENT]
  }
  
  merge(data) {
    this.label = data.label
    this.subject = data.subject
    this.content = data.content
  }
}

export class EmailTemplateSelector extends SelectBase{
  constructor(template = {
    label: null,
    content: null,
    subject: null
  }) {
    super()
    this._template = template
  }

  get ID() {
    return this._template.ID
  }

  get label() {
    return this._template.label
  }

  set label(val) {
    this._template.label = val
  }

  get subject() {
    return this._template.subject
  }

  set subject(val) {
    this._template.subject = val
  }

  get content() {
    return this._template.content
  }

  set content(val) {
    this._template.content = val
  }

  get readonly() {
    return this._template.readonly
  }

  merge(data) {
    this._template.merge(data)
  }
} 


