import httputil from "./api/util/http-util-crm";
import Vue from 'vue';

export default class Auth {
  constructor(repo, http, APIBaseURL) {
    if (!Auth.instance) {
      this._state = Vue.observable({
        tokenRefreshing: false,
      });
      this.APIBaseURL = APIBaseURL;
      this.http = http;
      this.repo = repo;
      Auth.instance = this;
    }
    return Auth.instance;
  }

  async request(url, config) {
    let tokens = await this.getTokensFromRepoOrRefresh(url ,config);
    if (tokens == null) {
      return httputil.errResult(401, null);
    }

    if (!config) {
      config = {};
    }
    if (!("headers" in config)) {
      config.headers = {};
    }
    config["headers"]["Authorization"] = "Bearer " + tokens.access;
    try {
      return await this.http.request(url, config);
    } catch (error) {
      return Promise.reject(httputil.processError(error));
    }
  }

  async getTokensFromRepoOrRefresh(url, config) {
    if (this.isTokenRefreshing()) {
      await this.waitUntilTokenRefreshed();
    }
    let tokens = this.getTokensFromRepo();

    if (!tokens) {
      return Promise.reject(httputil.errResult(401, null));
    }
    tokens = this.processTokensDate(tokens);
    let approxRequestTime = 8; // seconds
    let now = new Date().getTime() / 1000;
    // access token is active
    if (tokens && now < tokens.exp_access - approxRequestTime) {
      return tokens;
    }
    // refresh token expired
    if (tokens && now >= tokens.exp_refresh) {
      return Promise.reject(httputil.errResult(401, null));
    }
    try {
      return await this.updateTokensByRefreshToken(tokens.refresh);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async waitUntilTokenRefreshed() {
    await new Promise((resolve, reject) => {
      const state = this._state;
      new Vue({
        created() {
          this.$watch(() => state.tokenRefreshing, (value) => {
            if (!value) resolve();
          });
        },
      });
    });
  }

  isTokenRefreshing() {
    return this._state.tokenRefreshing;
  }

  storeTokensToRepo(tokens) {
    return this.repo.store("tokens", tokens);
  }

  removeTokensFromRepo() {
    this.repo.remove("tokens");
  }

  getTokensFromRepo() {
    let tokens = this.repo.get("tokens");
    return tokens;
  }

  processTokensDate(tokens) {
    return tokens;
  }

  async updateTokensByCredentials(formData) {
    try {
      let res = await this.getTokensByCredentials(formData);
      res = this.processTokensDate(res);
      this.storeTokensToRepo(res);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getTokensByCredentials(formData) {
    try {
      let res = await this.postNoAuth(`${this.APIBaseURL}token/`, {
        ...formData
      });

      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateTokensByRefreshToken(token) {
    try {
      this._state.tokenRefreshing = true;
      let res = await this.getTokensByRefreshToken(token);
      res = this.processTokensDate(res);
      this.storeTokensToRepo(res);
      this._state.tokenRefreshing = false;
      return res;
    } catch (error) {
      console.log('Refresh token error', error);
      this.removeTokensFromRepo();
      return Promise.reject(error);
    } finally {
      this._state.tokenRefreshing = false;
    }
  }

  async getTokensByRefreshToken(token) {
    try {
      let res = await this.postNoAuth(`${this.APIBaseURL}token/refresh/`, {
        refresh: token
      });
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async get(url, config) {
    if (!config) {
      config = {};
    }
    config.method = "get";

    let res = await this.request(url, config);
    return res;
  }

  async post(url, data, config) {
    if (!config) {
      config = {};
    }
    config.method = "post";
    config.data = data;
    return this.request(url, config);
  }

  async put(url, data, config) {
    if (!config) {
      config = {};
    }
    config.method = "put";
    config.data = data;

    let res = await this.request(url, config);
    return res;
  }

  async patch(url, data, config) {
    if (!config) {
      config = {};
    }
    config.method = "patch";
    config.data = data;

    let res = await this.request(url, config);
    return res;
  }

  async delete(url, config) {
    if (!config) {
      config = {};
    }
    config.method = "delete";

    let res = await this.request(url, config);
    return res;
  }

  async login(formData) {
    let res = await this.postNoAuth(`${this.APIBaseURL}token/`,
      {
        ...formData
      })
    return res
  }

  logout() {
    this.repo.remove('tokens');
    this._state.tokenRefreshing = false;
  }

  async getNoAuth(url, data, config) {
    if (!config) {
      config = {};
    }

    try {
      let res = await this.http.get(url, data, config);
      return res;
    } catch (error) {
      return Promise.reject(httputil.processError(error));
    }
  }

  async postNoAuth(url, data, config) {
    if (!config) {
      config = {};
    }
    try {
      let res = await this.http.post(url, data, config);
      return res;
    } catch (error) {
      return Promise.reject(httputil.processError(error));
    }
  }

  async putNoAuth(url, data, config) {
    let res = {};

    await this.http
      .put(url, data, config)
      .then(response => {
        res = response.data;
      })
      .catch(err => {
        res = httputil.processError(err);
      });

    return res;
  }
}
