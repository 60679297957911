import CRMauth from "@/services/CRMinitAuth"

const endpoint = 'candidate_colors/';

const colorList = () =>
  CRMauth.get(`${process.env.VUE_APP_CRM_API}${endpoint}`);

const createColor = color =>
  CRMauth.post(`${process.env.VUE_APP_CRM_API}${endpoint}`, color);

const updateColor = (id, newColor) =>
  CRMauth.put(`${process.env.VUE_APP_CRM_API}${endpoint}${id}/`, newColor);

const deleteColor = id =>
  CRMauth.delete(`${process.env.VUE_APP_CRM_API}${endpoint}${id}/`);


export default {
  colorList,
  createColor,
  updateColor,
  deleteColor
}