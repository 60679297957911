import JourneyAPI from '@/services/api/api-journey';

export default {
  namespaced: true,

  state: () => ({
    items: []
  }),

  getters: {
    items: state => state.items,
  },

  mutations: {
    set(state, items) {
      state.items = items;
    },

    loadMore(state, items) {
      items.forEach(item => state.items.push(item));
    },

    clearItems(state) {
      state.items = [];
    }
  },

  actions: {
    async list({ state, commit }, query) {
      try {
        if (query && query.cache && state.items) return state.items;
        let res = await JourneyAPI.candidateJourneys(query);
        commit('set', res.results);
        return res;
      } catch (error) {
        console.log(error, 'journeysList');
        return Promise.reject(error);
      }
    },

    async loadMore({ state, commit }, query) {
      try {
        if (query && query.cache && state.items) return state.items;
        let res = await JourneyAPI.candidateJourneys(query);
        commit('loadMore', res.results);
        return res;
      } catch (error) {
        console.log(error, 'journeysList');
        return Promise.reject(error);
      }
    }
  }
};
