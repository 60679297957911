import CRMauth from '@/services/CRMinitAuth';

const endPoint = `${process.env.VUE_APP_CRM_API}driving_schools`;

const list = query => {
  return CRMauth.get(`${endPoint}/report/`, { params: query });
};

const list_applications = query => {
  return CRMauth.get(`${process.env.VUE_APP_CRM_API}driving_school_applications/`, { params: query });
};

const list_transactions = query => {
  return CRMauth.get(`${process.env.VUE_APP_CRM_API}driving_school_transactions/`, { params: query });
};

const items = query => {
  return CRMauth.get(`${endPoint}/`, { params: query });
};

const post = data => {
  return CRMauth.post(`${endPoint}/`, data);
};

const put = (ID, data) => {
  return CRMauth.put(`${endPoint}/${ID}/`, data);
};

const remove = ID => {
  return CRMauth.delete(`${endPoint}/${ID}/`);
};

const report_csv = query => {
  return CRMauth.get(`${process.env.VUE_APP_CRM_API}driving_school_applications/report_csv/`, { params: query });
};

const make_payout = data => {
  return CRMauth.post(`${process.env.VUE_APP_CRM_API}driving_school_applications/make_payout/`, data);
};

export default {
  list,
  list_applications,
  list_transactions,
  items,
  post,
  put,
  remove,
  report_csv,
  make_payout
};
