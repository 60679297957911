import ROUTE_NAMES from '@/app/admin/router/router-const';
import { CRM_PERMS } from '@/util/const';

export default [
  {
    path: 'reapply-applications',
    component: () => import('../ReapplyTasksPage.vue'),
    name: ROUTE_NAMES.REAPPLY_APPLICATIONS,
    meta: {
      perms: CRM_PERMS.REAPPLY_TASK_LIST
    },
  }
];
