import { CM_DATES_ARG } from "@/app/admin/modules/course_managment/classes/CMDate"
import { CM_EXAM_CONFIG } from "@/app/admin/modules/course_managment/classes/CMExamConfig"

export const cm_1 = {
  [CM_DATES_ARG.DATE]: '2020-05-06',
  [CM_DATES_ARG.ID]: 1 
}
export const cm_2 = {
  [CM_DATES_ARG.DATE]: '2020-05-07',
  [CM_DATES_ARG.ID]: 1 
}
export const cm_3 = {
  [CM_DATES_ARG.DATE]: '2020-06-01',
  [CM_DATES_ARG.ID]: 1 
}
export const cm_4 = {
  [CM_DATES_ARG.DATE]: '2020-06-02',
  [CM_DATES_ARG.ID]: 1 
}
export const cm_5 = {
  [CM_DATES_ARG.DATE]: '2021-05-06',
  [CM_DATES_ARG.ID]: 1 
}

export const cm_list = [cm_1, cm_2, cm_3, cm_4, cm_5]

export const cm_config_1 = {
  id: 1,
  [CM_EXAM_CONFIG.EXAM_DATE]: '2100-04-05',
  [CM_EXAM_CONFIG.EXAM_TIME_FROM]: '10:00',
  [CM_EXAM_CONFIG.EXAM_TIME_TO]: '19:00',
  [CM_EXAM_CONFIG.MAX_STUDENTS]: '2'
}

export const cm_config_2 = {
  id: 2,
  [CM_EXAM_CONFIG.EXAM_DATE]: '2100-10-05',
  [CM_EXAM_CONFIG.EXAM_TIME_FROM]: '10:00',
  [CM_EXAM_CONFIG.EXAM_TIME_TO]: '16:00',
  [CM_EXAM_CONFIG.MAX_STUDENTS]: '8'
}

export const cm_conig_list = [cm_config_1, cm_config_2]
