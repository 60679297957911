import auth from "@/services/CRMinitAuth"

const list = () => {
  return auth.get(`${process.env.VUE_APP_CRM_API}labels/`)
}

const post = data => 
  auth.post(`${process.env.VUE_APP_CRM_API}labels/`, data)

const update = (ID, data) => 
  auth.put(`${process.env.VUE_APP_CRM_API}labels/${ID}`, data)

const remove = ID => 
  auth.delete(`${process.env.VUE_APP_CRM_API}labels/${ID}/`,)

export default {
  list,
  post,
  remove,
  update
}