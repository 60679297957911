import ROUTE_NAMES from '@/app/admin/router/router-const';
import { CRM_PERMS } from "@/util/const";

export default [
  {
    path: 'driving-schools',
    component: () => import('../DrivingSchoolsPage.vue'),
    meta: {perm: CRM_PERMS.DRIVING_SCHOOLS},
    children: [
      {
        path: '',
        redirect: {name: ROUTE_NAMES.DRIVING_SCHOOLS},
        meta: {perm: CRM_PERMS.DRIVING_SCHOOLS}
      },
      {
        path: 'list',
        name: ROUTE_NAMES.DRIVING_SCHOOLS,
        component: () => import('../components/list-page/SchoolPage.vue'),
        meta: {perm: CRM_PERMS.DRIVING_SCHOOLS}
      },
      {
        path: 'applications',
        name: ROUTE_NAMES.DRIVING_SCHOOLS_APPLICATIONS,
        component: () => import('../components/applications-page/SchoolApplicationPage.vue'),
        meta: {perm: CRM_PERMS.DRIVING_SCHOOLS}
      },
      {
        path: 'transactions',
        name: ROUTE_NAMES.DRIVING_SCHOOLS_TRANSACTIONS,
        component: () => import('../components/transactions-page/SchoolTransactionsPage.vue'),
        meta: {perm: CRM_PERMS.DRIVING_SCHOOLS}
      },
    ]
  },
]