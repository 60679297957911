import api from '@/services/api/api-cm';
import {dateFormating} from '@/util';
import {CourseModelDetailed} from '@/app/admin/models/courseModel';
import CMExamConfig, {CMExamConfigApiData, CMExamHourFormatter} from '../classes/CMExamConfig';
import {CMLocationApiData} from '../classes/CMLocationConfigModel';
import PaginationModel from '@/models/paginationModel';
import {CourseDate} from './models/cm-date.model';

export default class CMService {
  constructor(api) {
    this.api = api;
  }

  async courseList(query) {
    try {
      let res = await api.courseListDetailed(query);
      let courseListArr = res;
      if (!Array.isArray(res) && Array.isArray(res.results)) courseListArr = res.results;
      return courseListArr.map(item => new CourseModelDetailed(item));
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async courseListOnlyDates(query) {
    try {
      const list = await api.courseListOnlyDates(query);
      return list.map(item => new CourseDate(item));
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async courseListWithPagination(query) {
    try {
      let res = await api.courseListDetailed(query);
      const list = res.results.map(item => new CourseModelDetailed(item));
      const pagination = new PaginationModel(res);
      return {
        list,
        pagination
      };
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async createCourse(data) {
    try {
      let res = await api.createCourse({
        city: data.city,
        date: data.date ? dateFormating(data.date).join('-') : null,
        notes: data.notes,
        max_students: data.maxStudents,
        is_visible_on_landing: data.isVisibleOnLanding,
        name: data.name,
        reservable_license_types: data.reservableLicenseTypes || [],
      })
      return res
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async updateCourse(courseID, data) {
    try {
      return await api.updateCourse(courseID, {
        notes: data.notes,
        max_students: data.maxStudents,
        is_visible_on_landing: data.is_visible_on_landing,
        name: data.name,
      });
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getCourse(courseID) {
    try {
      return await api.getCourse(courseID);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateCourseReservableLicenseTypes(courseID, reservableLicenseTypes) {
    try {
      return await api.updateCourse(courseID, {
        reservable_license_types: reservableLicenseTypes
      });
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async removeCourse(courseID, data) {
    try {
      return await api.removeCourse(courseID)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async saveNote(courseId, notes) {
    return await this.updateCourse(courseId, {notes})
  }

  async createExam(courseID, data) {
    try {
      let apiData;
      if (data.rangeMode !== null) apiData = new CMExamConfigApiData(data).convertExam();
      else apiData = new CMExamConfigApiData(data).convert();

      let res = await api.createExam(courseID, apiData);
      return new CMExamConfig(new CMExamHourFormatter(res).execute());
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getConfig(courseID, query) {
    try {
      let res = await api.getConfig(courseID, query);
      let list = res.map(item => new CMExamConfig(
        new CMExamHourFormatter(item).execute()
      ));
      return list;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async createConfig(courseID, data) {
    try {

      let apiData;
      if (data.rangeMode !== null) apiData = new CMExamConfigApiData(data).convertExam();
      else apiData = new CMExamConfigApiData(data).convert();

      let res = await api.createConfig(courseID, apiData);
      return new CMExamConfig(new CMExamHourFormatter(res).execute());
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateConfig(payload) {
    try {

      let apiData;
      if (payload.data.rangeMode !== null) apiData = new CMExamConfigApiData(payload.data).convertExam();
      else apiData = new CMExamConfigApiData(payload.data).convert();

      let res = await api.updateConfig({...payload, data: apiData});
      return new CMExamConfig(new CMExamHourFormatter(res).execute());
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async removeConfig(payload) {
    try {
      let res = await api.removeConfig(payload)
      return res
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async createCourseConfiguration(courseID, data) {

    try {
      let res = await api.createCourseConfiguration(courseID, {
        ...new CMLocationApiData(data).convert()
      })
      return res
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async updateCourseConfiguration(courseID, configurationID, data) {
    try {
      let res = await api.updateCourseConfiguration(courseID, configurationID, {
        ...new CMLocationApiData(data).convert()
      })
      return res
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
