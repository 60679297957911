import packagesService from '@/services/packages-service.js';
import { PACKAGES_TYPES } from '@/util/const';

export default {
  namespaced: true,

  state: () => ({
    packages: null
  }),

  getters: {
    list: state => state.packages,

    getPublicPlans: (state) => {
      return state.packages.filter(item => item.type === PACKAGES_TYPES.PLAN_TYPE_PUBLIC);
    },
    getOfflinePlans: (state) => {
      return state.packages.filter(item => item.type === PACKAGES_TYPES.PLAN_TYPE_OFFLINE);
    },
    getReapplyPlans: (state) => {
      return state.packages.filter(item => item.type === PACKAGES_TYPES.PLAN_TYPE_REAPPLY);
    },
    getExternalPlans: (state) => {
      return state.packages.filter(item => item.type === PACKAGES_TYPES.PLAN_TYPE_EXTERNAL);
    }
  },

  mutations: {
    set(state, items) {
      state.packages = items;
    },
  },

  actions: {
    async list({ state, commit }, params) {
      try {
        if (params && params.cache && state.packages) return state.packages;
        let res = await new packagesService().listCached();
        commit('set', res);
        return res;
      } catch (error) {
        return Promise.reject(error);
      }
    }
  }
};
