export default class UserDTO {
  constructor(user = {}) {
    this.ID = user.id;
    this.joinedDate = user.date_joined;
    this.isActive = user.is_active;
    this.isSuperuser = user.is_superuser;
    this.username = user.username;
    this.slotsLoadingStatus = user.slot_loading_status;
    this.slotsExportTime = user.slots_export_time;
    this.logsWebsocketPath = user.logs_websocket_path;
    this.crmAvaliablePerms = user.crm_permissions;
    this.crmSuperUser = user.crm_full_access;
  }
}
