import CourseService from "@/app/admin/models/courseService";
import apiCourses from "@/services/api/api-courses";

export default {
  namespaced: true,

  state: () => ({
    activeItem: null
  }),

  getters: {},

  mutations: {
    setActiveItem(state, value) {
      state.activeItem = value;
    }
  },

  actions: {
    async updateActiveItem({commit, state}) {
      if (!state.activeItem) return;
      try {
        let res = await new CourseService(apiCourses).getCourseDay(state.activeItem.id);
        commit('setActiveItem', res);
        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  }
};
