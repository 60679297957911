import CRMauth from '@/services/CRMinitAuth';

const featureFlags = query =>
  CRMauth.get(`${process.env.VUE_APP_CRM_API}feature_flags/`, {
    params: query
  });

export default {
  featureFlags
};
