import ROUTE_NAMES from '@/app/admin/router/router-const';
import { CRM_PERMS } from '@/util/const';

export default [
  {
    path: 'candidates',
    component: () => import('../CandidatesPage.vue'),
    meta: {
      perm: 'csss'
    },
    children: [
      {
        path: '',
        redirect: {name: ROUTE_NAMES.CANDIDATES_TASKS}
      },
      {
        path: 'all',
        name: ROUTE_NAMES.CANDIDATES_ALL,
        component: () => import('../CandidatesAllPage.vue'),
        meta: {
          perms: CRM_PERMS.CANDIDATES
        },
      },
      {
        path: 'tasks',
        name: ROUTE_NAMES.CANDIDATES_TASKS,
        component: () => import('../CandidatesTasksPage.vue'),
        meta: {
          perms: CRM_PERMS.CANDIDATES
        },
      },
      {
        path: 'wait',
        name: ROUTE_NAMES.CANDIDATES_WAIT,
        component: () => import('../CandidatesWaitPage.vue'),
        meta: {
          perms: CRM_PERMS.CANDIDATES
        },
      },
      {
        path: 'need_authorization',
        name: ROUTE_NAMES.NEED_AUTHORIZATION,
        component: () => import('../CandidatesNeedAuthorizationPage.vue'),
        meta: {
          perms: CRM_PERMS.CANDIDATES
        },
      },
      {
        path: 'reserve_exchange',
        name: ROUTE_NAMES.CANDIDATES_RESERVE_EXCHANGE,
        component: () => import('../CandidatesReservePage.vue.vue'),
        meta: {
          perms: CRM_PERMS.CANDIDATES
        },
      },
      {
        path: 'reserved_exchanged',
        name: ROUTE_NAMES.CANDIDATES_RESERVED_EXCHANGED,
        component: () => import('../CandidatesReservedExchangedPage.vue'),
        meta: {
          perms: CRM_PERMS.CANDIDATES
        },
      },
      {
        path: 'processed',
        name: ROUTE_NAMES.CANDIDATES_PROCESSED,
        component: () => import('../CandidatesProcessedPage.vue'),
        meta: {
          perms: CRM_PERMS.CANDIDATES
        },
      },
      {
        path: 'canceled_list',
        name: ROUTE_NAMES.CANDIDATES_CANCEL,
        component: () => import('../CandidatesCanceledPage.vue'),
        meta: {
          perms: CRM_PERMS.CANDIDATES
        },
      },
    ]
  },
];
