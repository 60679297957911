<template lang="pug">
  v-snackbar( :value="showing" 
    @input="handleClose" 
    :timeout="timeout" :top="props.top" :left="props.left"  :right="props.right"
    :color="props.color"
    max-width="290")
    component(:is="component" @onClose="handleClose" v-bind="props")
</template>

<script>
import EventBus from "@/util/event-bus";
import { EVENT_BUS } from "@/util/const";
import SnackbarBase from "@/components/snackbar-content/SnackbarBase.vue"

export default {
  data: () => ({
    component: null,
    timeout: 3000,
    props: {},
    showing: false,
  }),

  created() {
    EventBus.$on(EVENT_BUS.SNACKBAR, ({ component = SnackbarBase, timeout = 3000, props = null }) => {
      this.component = component
      this.timeout = timeout
      this.props = props
      this.showDialog();
    });
  },

  methods: {
    showDialog(msg, status) {
      this.showing = true;
    },

    handleClose () {
      this.component = null
      this.showing = false
    },
  }
};
</script>
