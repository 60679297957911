import PaginationDTO from '@/DTO/paginationDTO';

export default class PaginationModel {
  constructor(item) {
    Object.assign(this, new PaginationDTO(item));
  }

  get totalPages() {
    return Math.ceil(this.count / this.size);
  }

  get isLastPage() {
    return this.page === this.totalPages;
  }

  loadMore() {
    this.page++;
  }
}
