import { auth } from '@/main';
import CRMauth from '@/services/CRMinitAuth';

const list = query =>
  auth.get(`${process.env.VUE_APP_LANDING_API}cities/`, {
    params: query
  });

const listCRM = query =>
  CRMauth.get(`${process.env.VUE_APP_CRM_API}common/exam_locations/`, {
    params: query
  });

const listCRMCourse = query => CRMauth.get(`${process.env.VUE_APP_CRM_API}common/cities/`, {
  params: query
});

const listCRMCourseWithAdditionalInfo = query => CRMauth.get(`${process.env.VUE_APP_CRM_API}common/cities/with_additional_info/`, {
  params: query
});

const listCourseLocations = query => CRMauth.get(`${process.env.VUE_APP_CRM_API}common/meeting_rooms/`, {
  params: query
});

export default {
  list,
  listCRM,
  listCRMCourse,
  listCRMCourseWithAdditionalInfo,
  listCourseLocations
};
