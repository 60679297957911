import CRMauth from "@/services/CRMinitAuth"
import {cm_list} from "@/../tests/unit/__utils__/mock-cm"

// const courseList = query => {
//   return Promise.resolve(cm_list)
// }

const courseList = query =>
  CRMauth.get(`${process.env.VUE_APP_CRM_API}common/courses/`, {
    params: query
  });

const courseListDetailed = query =>
  CRMauth.get(`${process.env.VUE_APP_CRM_API}common/courses/detailed/`, {
    params: query
  });

const courseListOnlyDates = query =>
  CRMauth.get(`${process.env.VUE_APP_CRM_API}common/courses/only_dates/`, {
    params: query
  });

const getCourse = ID =>
  CRMauth.get(`${process.env.VUE_APP_CRM_API}common/courses/${ID}/`);

const createCourse = data =>
  CRMauth.post(`${process.env.VUE_APP_CRM_API}common/courses/`, data);

const updateCourse = (courseID, data) =>
  CRMauth.put(`${process.env.VUE_APP_CRM_API}common/courses/${courseID}/`, data);

const removeCourse = (courseID) =>
  CRMauth.delete(`${process.env.VUE_APP_CRM_API}common/courses/${courseID}/`);

const createExam = (courseID, data) =>
  CRMauth.post(`${process.env.VUE_APP_CRM_API}common/courses/${courseID}/course_exams/`, data);

const getConfig = (courseID, query) =>
  CRMauth.get(`${process.env.VUE_APP_CRM_API}common/courses/${courseID}/course_exams/`, {
    params: query
  });

const createConfig = (courseID, data) =>
  CRMauth.post(`${process.env.VUE_APP_CRM_API}common/courses/${courseID}/course_exams/`, data);

const updateConfig = payload =>
  CRMauth.put(`${process.env.VUE_APP_CRM_API}common/courses/${payload.courseID}/course_exams/${payload.settingID}/`, payload.data);

const removeConfig = payload =>
  CRMauth.delete(`${process.env.VUE_APP_CRM_API}common/courses/${payload.courseID}/course_exams/${payload.settingID}/`);

export default {
  courseList,
  getCourse,
  createCourse,
  createExam,
  courseListDetailed,
  getConfig,
  createConfig,
  removeConfig,
  updateCourse,
  removeCourse,
  courseListOnlyDates,
  updateConfig
};
