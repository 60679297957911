import CitiesAPI from '@/services/api/api-cities';
import CityModel, {CityWithAdditionalInfoModel} from '@/models/cityModel';

// Time in milliseconds
const CACHED_TIME = 60000;

export default {
  namespaced: true,

  state: () => ({
    last_updated_time: new Date().getTime(),
    items: null,
    itemsWithAdditionalInfo: null
  }),

  getters: {
    items: state => state.items,
    itemsWithAdditionalInfo: state => state.itemsWithAdditionalInfo,
    has: state => state.items ? true : false,
  },

  mutations: {
    set(state, res) {
      state.items = res.map(item => new CityModel(item));
    },
    setCoursesWithAdditionalInfo(state, res) {
      state.last_updated_time = new Date().getTime();
      state.itemsWithAdditionalInfo = res.map(item => new CityWithAdditionalInfoModel(item));
    }
  },

  actions: {
    async listWithAdditionalInfo({state, commit}) {
      try {
        const currentTime = new Date().getTime();
        if (state.itemsWithAdditionalInfo && (currentTime - state.last_updated_time) <= CACHED_TIME) return state.items;
        let res = await CitiesAPI.listCRMCourseWithAdditionalInfo();
        commit('setCoursesWithAdditionalInfo', res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async list({state, commit}, params) {
      try {
        if (params && params.cache && state.items) return state.items;
        let res = await CitiesAPI.listCRMCourse();
        commit('set', res);
        return res;
      } catch (error) {
        console.log(error, 'cityCourse');
        return Promise.reject(error);
      }
    },
  }
};
