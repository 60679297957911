import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import * as filters from '@/util/filters';
import VueMeta from 'vue-meta';
import Auth from '@/services/initAuth';
import RouterNames from './router/router-const';
import Dayjs from 'dayjs';
import PortalVue from "portal-vue";
import Notifications from 'vue-notification';
import velocity from 'velocity-animate';
import infiniteScroll from 'vue-infinite-scroll';
import CRMauth from '@/services/CRMinitAuth';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import VTooltip from 'v-tooltip';
import VueScrollTo from 'vue-scrollto';
import Longpress from "@/util/longpress.directive"

Vue.config.productionTip = false;
export const auth = Object.freeze(Auth);

Dayjs.extend(relativeTime);
Dayjs.extend(utc);
Dayjs.extend(timezone);
Dayjs.extend(customParseFormat);

Vue.prototype.$ROUTER_NAMES = RouterNames;
Vue.prototype.$auth = CRMauth;
Vue.prototype.$dates = Dayjs;
Vue.use(PortalVue);
Vue.use(Notifications, {velocity});
Vue.use(infiniteScroll);
Vue.use(VueMeta);

Vue.use(VTooltip, {
  defaultHtml: false
});

Vue.directive('longpress', {
  bind: Longpress
})
Vue.use(VueScrollTo, {
  container: 'body',
  duration: 500,
  easing: 'ease',
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
});

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
