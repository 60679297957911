export default class AuthRepoLocalStorage {
  constructor() {
    this.storage = {}
  }
  /**
   *
   */
  store(key, val) {
    this.storage[key] = val
  }

  /**
   *
   */
  get(key) {
    return this.storage[key]
  }

  /**
   *
   */
  remove(key) {
    delete this.storage[key]
  }
}
