import API from '@/services/api/api-courses';
import { CourseMini } from '@/app/admin/models/courseModel';

export default {
  namespaced: true,

  state: () => ({
    items: []
  }),

  getters: {
    items: state => state.items,
  },

  mutations: {
    set(state, items) {
      state.items = items.map(item => new CourseMini(item).get());
    }, update(state, params) {
      let index = state.items.findIndex(item => item.id === params.data.id);
      if (index < 0) return;
      for (let field in state.items[index]) {
        if (field in params.data) state.items[index][field] = params.data[field];
      }
    }
  },

  actions: {
    async list({ state, commit }, params) {
      try {
        if (params && params.cache && state.items) return state.items;
        let res = await API.allCourses(params.query);
        commit('set', res);
        return res;
      } catch (error) {
        return Promise.reject(error);
      }
    }
  }
};
