<template>
  <v-dialog :value="showing" @input="handleClose" max-width="290">
    <component :is="component" @onClose="handleClose" v-bind="props"/>
  </v-dialog>
</template>

<script>
import EventBus from "@/util/event-bus";
import { EVENT_BUS } from "@/util/const";

export default {
  data: () => ({
    component: null,
    title: '',
    props: null,
    showing: false
  }),

  created() {
    EventBus.$on(EVENT_BUS.DIALOG_ERR, ({ component, title = '', props = null }) => {
      this.component = component
      this.title = title
      this.props = props
      this.showDialog();
    });
  },

  methods: {
    showDialog(msg, status) {
      this.showing = true;
    },

    handleClose() {
      this.component = null
      this.showing = false
    },
  }
};
</script>
