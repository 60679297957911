import CRMauth from "@/services/CRMinitAuth"

const list = query =>
  CRMauth.get(`${process.env.VUE_APP_CRM_API}common/product_names/`, {
    params: query
  });


export default {
  list
}