import { ACTIVE_TYPE } from "./slots-conts"

export default {
  namespaced: true,

  state: () => ({
    value: ACTIVE_TYPE.ALL
  }),

  getters: {
    value: state => state.value,

    topActive(state) {
      if(state.value === ACTIVE_TYPE.ALL) return true
      if(state.value === ACTIVE_TYPE.TOP) return true
      return false
    },
    
    mijnActive(state) {
      if(state.value === ACTIVE_TYPE.ALL) return true
      if(state.value === ACTIVE_TYPE.MIJN) return true
      return false
    }
  },

  mutations: {
    set(state, val) {
      state.value = val
    }
  }
}