import ROUTER_NAMES from '@/app/auth/core/router-const';
import errMixin from '@/mixins/errors.mixin';

export default async function auth({ next, store }) {
  try {
    if (store.state.user.current) return next();
    return next();
  } catch (err) {
    errMixin.methods.processError(err);
    next({ name: ROUTER_NAMES.LOGIN });
    return;
  }
}
