import Dayjs from 'dayjs';
import { orderBy } from 'lodash';
import {
  EVENT_BUS,
  DATE_FORMAT_DEFAULT,
  DATE_FORMAT_HOUR_DEFAULT,
  DATE_FORMAT_CRM_BACKEND,
  HOUR_FORMAT_DEFAULT,
  DOW,
  DOW_DUTCH,
  DOW_DUTCH_DDD,
  MONTH_DUTCH,
  DATE_FORMAT_HOUR_SECONDS_DEFAULT
} from '@/util/const';
import EventBus from '@/util/event-bus';

export function getMultipleQueryValueWithNumber(query) {
  if (typeof query === 'string') return [+query]
  else if (query) return query.map(Number)
  return []
}

export function getBooleanQueryValue(query) {
  return !!query;
}

export function getMultipleQueryValue(query) {
  if (typeof query === 'string') return [query]

  else if (query) return query
  return []
}

export function showSnackbar(msg, params = {color: 'success'}) {
  EventBus.$emit(EVENT_BUS.SNACKBAR, {
    props: {
      top: true,
      right: true,
      color: params.color,
      msg: msg
    }
  });
}

export function notSetValueIFNullOrUndefined(value) {
  if (value === null || value === undefined) {
    return
  }
  return value
}

export function transformQueryBoolean(val) {
  if (val === 'false') return false
  if (val === 'true') return true
  return val
}

export function dateFormating(date) {
  if (!date) return
  let [day, month, year] = date.split('-')
  return [year, month, day]
}

export function DDMMYYYYFormatting(date) {
  if (!date) return
  let _date = date.split('-')
  _date = [_date[2], _date[1], _date[0]].join('-')
  return _date
}

export function convertToDefaultDateFormatDDD(date) {
  const dayIndex = new Date(date).getDay();
  return `${getDOWDutchDDD(dayIndex)} ${Dayjs(date).format(DATE_FORMAT_DEFAULT)}`;
}

export function convertToDefaultDateFormatDDDInBeginning(date) {
  const dayIndex = new Date(date).getDay();
  return `${getDOWDutchDDD(dayIndex)} ${Dayjs(date).format(DATE_FORMAT_DEFAULT)}`;
}

export function moveArrayElem(arr, from, to, on = 1) {
  arr.splice(to, 0, ...arr.splice(from, on));
}

export function convertToDefaultDateFormat(date) {
  if (!date) return;
  return Dayjs(new Date(date).toLocaleString("en-US", {timeZone: "Europe/Amsterdam"})).format(DATE_FORMAT_DEFAULT);
}

export function convertToDefaultDateHourFormat(date) {
  if (!date) return;
  return Dayjs(new Date(date).toLocaleString("en-US", {timeZone: "Europe/Amsterdam"})).format(DATE_FORMAT_HOUR_DEFAULT);
}

export function convertToDefaultDatetime(date) {
  if (!date) return;
  return Dayjs(new Date(date).toLocaleString("en-US", {timeZone: "Europe/Amsterdam"})).format(DATE_FORMAT_HOUR_SECONDS_DEFAULT);
}

export function convertToDefaultHourFormat(date) {
  if (!date) return;
  return Dayjs(new Date(date).toLocaleString("en-US", {timeZone: "Europe/Amsterdam"})).format(HOUR_FORMAT_DEFAULT);
}

export function convertToDefaultBackendFormat(date) {
  return Dayjs(date).format(DATE_FORMAT_CRM_BACKEND);
}

export function convertToShortMonth(date) {
  return Dayjs(date.split('-')).format('MMM');
}

export function convertToShortMonthDutch(date) {
  return MONTH_DUTCH[
    new Date(
      dateFormating(date).join('-')
    ).getMonth()
    ]
}

export function formatingToDDD(date) {
  let _date = dateFormating(date).join('-')
  return DOW_DUTCH_DDD[new Date(_date).getDay()]
}

export function convertToDefaultDatetimeBackendFormat(datetime) {
  let [date, time] = datetime.split(' ')
  let correctDate = dateFormating(date)
  return [correctDate.join('-'), time].join(' ')
}

export function getDOW(dayIndex) {
  return DOW[dayIndex]
}

export function getDOWDutch(dayIndex) {
  return DOW_DUTCH[dayIndex]
}

export function getDOWDutchDDD(dayIndex) {
  return DOW_DUTCH_DDD[dayIndex]
}

export function isDateFuture(date) {
  if (!date) return false
  let _date = new Date(dateFormating(date).join('-'))
  let year = _date.getFullYear()
  let month = _date.getMonth()
  let day = _date.getDate()
  let now = new Date(Date.now())
  let nowYear = now.getFullYear()
  let nowMonth = now.getMonth()
  let nowDay = now.getDate()
  if (+year > +nowYear) return true
  if (+month > +nowMonth) return true
  if (+day >= +nowDay) return true
  return false
}

export function isMac() {
  return navigator.platform.indexOf('Mac') > -1
}

export function timePassedTillNow(date) {
  const utc = require('dayjs/plugin/utc')
  Dayjs.extend(utc)
  const dateFrom = Dayjs(new Date() - date);
  const hours = dateFrom.utc().hour() ? `${dateFrom.utc().hour()} hours` : null;
  const minutes = dateFrom.minute() ? `${dateFrom.minute()} min` : null;
  const seconds = `${dateFrom.second()} sec`;
  if (hours) return hours;
  if (minutes) return minutes;
  return seconds;
}

export function truncateString(str, maxlength) {
  return (str.length > maxlength) ? str.slice(0, maxlength - 1) + '…' : str;
}

export function DeleteMS(hours) {
  if (!hours) return
  let splited = hours.split(':')
  return [splited[0], splited[1]].join(':')
}

export function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export function downloadFile(value, fileName, fileType = 'text/csv') {
  let blob = new Blob([value], {type: fileType});
  let link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  return link.click();
}

export function sortByDate(array, type = 'asc') {
  return array.sort(function (a, b) {
    if (type === 'asc') return new Date(a.date) - new Date(b.date);
    else return new Date(b.date) - new Date(a.date);
  });
}

export function sortByFields(array, fields) {
  // https://lodash.com/docs/4.17.15#orderBy
  return orderBy(array, fields, fields.map(field => 'asc'));
}

export function inverseSortByFields(array, fields) {
  // https://lodash.com/docs/4.17.15#orderBy
  return orderBy(array, fields, fields.map(field => 'desc'));
}

export function isFieldsInObjectsAreSame(obj1, obj2, fields) {
  let isSame = true;
  fields.forEach(field => {
    if (obj1[field] !== obj2[field]) {
      isSame = false;
    }
  })
  return isSame;
}
