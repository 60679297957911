import auth from "@/services/LandingInitAuth"

const choices = () =>
  auth.get(`${process.env.VUE_APP_LANDING_ADMIN_API}reviews/choices/`);

const list = query =>
  auth.get(`${process.env.VUE_APP_LANDING_ADMIN_API}reviews/`, {
    params: query
  });
  
const remove = ID => 
  auth.delete(`${process.env.VUE_APP_LANDING_ADMIN_API}reviews/${ID}/`)
  
const removeBulk = data => 
  auth.post(`${process.env.VUE_APP_LANDING_ADMIN_API}reviews/bulk_delete/`, data)

const put = (ID, data) => 
  auth.put(`${process.env.VUE_APP_LANDING_ADMIN_API}reviews/${ID}/`, data)

const create = data =>
  auth.post(`${process.env.VUE_APP_LANDING_ADMIN_API}reviews/`, data);

export default {
  list,
  choices,
  remove,
  removeBulk,
  create,
  put
}