import ROUTE_NAMES from '@/app/admin/router/router-const'
export default [
  {
    path: 'course-leaders',
    name: ROUTE_NAMES.COURSE_LEADERS,
    component: () => import('../CourseLeadersPage.vue'),
    meta: {
      perms: ROUTE_NAMES.CANDIDATES
    }
  }
];
