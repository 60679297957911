import Auth from '@/app/auth/core/router-const';
import { CRM_PERMS } from '@/util/const';

export default Object.freeze({
  RESERVATION: 'reservation',
  RESERVATION_ITEM: 'reservation-item',
  RESERVATION_ADD: 'reservation-add',
  CANDIDATES: CRM_PERMS.CANDIDATES,
  CANDIDATES_ALL: 'candidate_all',
  CANDIDATES_ITEM: 'candidates_item',
  CANDIDATES_TASKS: 'CANDIDATES',
  CANDIDATES_WAIT: 'candidate_wait',
  NEED_AUTHORIZATION: 'need_authorization',
  CANDIDATES_CANCEL: 'candidate_cancel',
  CANDIDATES_RESERVE_EXCHANGE: 'candidate_reserve_exchange',
  CANDIDATES_RESERVED_EXCHANGED: 'candidate_reserved_exchanged',
  CANDIDATES_PROCESSED: 'candidate_processed',
  RESULTS: CRM_PERMS.RESULTS,
  SLOTS: CRM_PERMS.SLOTS,
  CBR_UPDATES: CRM_PERMS.CBR_UPDATES,
  CBR_ACC: CRM_PERMS.CBR_ACCOUNTS,
  COURSE_MANAGEMENT: CRM_PERMS.COURSE_MANAGEMENT,
  MASTER_PLANNING: 'course_management_all',
  COURSES: CRM_PERMS.COURSES,
  COURSE_LEADERS: 'course-leaders',
  DRIVING_SCHOOLS: CRM_PERMS.DRIVING_SCHOOLS,
  DRIVING_SCHOOLS_APPLICATIONS: 'driving-school-applications',
  DRIVING_SCHOOLS_TRANSACTIONS: 'driving-school-transactions',
  TASK_APPLICATIONS: 'task_applications',
  REAPPLY_APPLICATIONS: 'reapply_applications',
  FEEDBACK: 'feedback',
  ...Auth,
});

export const AFFILIATES = {
  [CRM_PERMS.COURSES]: CRM_PERMS.COURSES_LEADERS
};
