import api from '@/services/api/api-courseLeaders';
import { CourseLeader } from '@/app/admin/modules/course_leaders/core/models/courseLeaderModel';

export default class CourseLeadersService {
  constructor() {
    if (CourseLeadersService.instance) {
      return CourseLeadersService.instance;
    }

    this.loading = false;

    CourseLeadersService.instance = this;
    return this;
  }

  async listCached(query) {
    if (this.loading && this.cachedPromise) {
      return this.cachedPromise;
    } else if (this.cachedItems) {
      return this.cachedItems;
    }
    this.loading = true;
    this.cachedPromise = this.list(query);
    this.cachedItems = await this.cachedPromise;
    this.loading = false;
    return this.cachedItems;
  }

  clearCache() {
    delete this.cachedItems;
  }

  async list(query) {
    try {
      let res = await api.list(query);
      if (!res) throw new Error('Something wrong and account not loaded. Please, reload page or try later.');
      return {
        results: res.map(item => new CourseLeader(item)),
      };
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async statsListByLeader(query) {
    try {
      let res = await api.statsListByLeader(query);

      if (!res) throw new Error('Something wrong and account not loaded. Please, reload page or try later.');
      return {
        results: res.results,
        totals: res.totals,
        pagination: {
          count: res.count,
          next: res.next,
          previous: res.previous
        }
      };
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async statsListByLeaderOnCourse(query) {
    try {
      let res = await api.statsListByLeaderOnCourse(query);

      if (!res) throw new Error('Something wrong and account not loaded. Please, reload page or try later.');
      return {
        results: res.results,
        totals: res.totals,
        pagination: {
          count: res.count,
          next: res.next,
          previous: res.previous
        }
      };
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async statsListByCourse(query) {
    try {
      let res = await api.statsListByCourse(query);

      if (!res) throw new Error('Something wrong and account not loaded. Please, reload page or try later.');
      return {
        results: res.results,
        totals: res.totals,
        pagination: {
          count: res.count,
          next: res.next,
          previous: res.previous
        }
      };
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async create(data) {
    try {
      return await api.create(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async update(data) {
    try {
      return await api.update(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async sessionsList() {
    try {
      return await api.listSessions();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getCSVReportByLeaderOnCourse(payload) {
    try {
      return await api.getCSVReportByLeaderOnCourse(payload);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getCSVReportByLeader(payload) {
    try {
      return await api.getCSVReportByLeader(payload);
    } catch (err) {
      return Promise.reject(err);
    }
  }
}
