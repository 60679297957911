import CandidatesColorService from '../../app/admin/modules/candidates/core/candidates-color-service';

const svc = new CandidatesColorService();

export default {
  namespaced: true,

  state: () => ({
    colors: []
  }),

  mutations: {
    setColors(state, items) {
      state.colors = items;
    },
    createColor(state, color) {
      state.colors.push(color);
    },
    updateColor(state, newColor) {
      state.colors = state.colors.map(color => color.id === newColor.id ? newColor : color);
    },
    deleteColor(state, id) {
      state.colors = state.colors.filter(color => color.id !== id);
    }
  },

  actions: {
    async colorsList({ state, commit }) {
      try {
        let res = await svc.list();
        commit('setColors', res);
        return res;
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async createColor({ state, commit }, data) {
      try {
        console.log(data)
        const newColor = await svc.create(data);
        commit('createColor', newColor);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async updateColor({ state, commit }, data) {
      try {
        const newColor = await svc.update(data);
        commit('updateColor', newColor);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async deleteColor({ state, commit }, id) {
      try {
        await svc.delete(id);
        commit('deleteColor', id);
      } catch (error) {
        return Promise.reject(error);
      }
    }
  }
};