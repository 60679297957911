import LeadersAPI from '@/services/api/api-courseLeaders';

export default {
  namespaced: true,

  state: () => ({
    leadersList: null,
  }),

  getters: {
    leadersList: state => state.leadersList
  },

  mutations: {
    set(state, items) {
      state.leadersList = items;
    },
  },

  actions: {
    async leadersList({state, commit}, params) {
      try {
        if (params && params.cache && state.leadersList) return state.leadersList;
        let res = await LeadersAPI.list();
        commit('set', res.filter(item => !item.is_archived));
        return res;
      } catch (error) {
        console.log(error, 'courseLeaders');
        return Promise.reject(error);
      }
    },
  }
};
