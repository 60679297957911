import api from '@/services/api/api-packages';

export default class PackagesService {
  constructor() {
    if (PackagesService.instance) {
      return PackagesService.instance;
    }

    this.loading = false;

    PackagesService.instance = this;
    return this;
  }

  async listCached(query) {
    if (this.loading && this.cachedPromise) {
      return this.cachedPromise;
    } else if (this.cachedItems) {
      return this.cachedItems;
    }
    this.loading = true;
    this.cachedPromise = this.list(query);
    this.cachedItems = await this.cachedPromise;
    this.loading = false;
    return this.cachedItems;
  }

  async list(query) {
    try {
      let res = await api.onlinePlansList(query);
      if (!res) throw new Error('Something wrong and account not loaded. Please, reload page or try later.');
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
