import EventBus from "../util/event-bus";
import { EVENT_BUS } from "@/util/const";
import router from "../router";
import DialogErr from "../components/dialog-content/DialogError.vue";

export default {
  methods: {
    hasError(res) {
      if (!res) {
        return false;
      }

      if (res.status !== "ERR") {
        return false;
      }

      return true;
    },

    processError(res, params) {
      if (res.message && res.message.length) {
        this.showErrorModal(res.message);
        return true;
      }
      if (!this.hasError(res)) {
        return false;
      }

      if (this.redirectToLoginPageIfAuthRequired(res, params)) {
        return true;
      }
      this.showErrorModal(res.error.message);
      return true;
    },

    processErrorsArr(resArr) {
      if (resArr.length < 1) {
        return false;
      }

      for (let i = 0; i < resArr.length; i++) {
        if (this.processError(resArr[i])) {
          return true;
        }
      }

      return false;
    },

    processErrorWithForm(res, params = {showErrorModal: true}) {
      if (!this.hasError(res)) {
        return false;
      }
      if (this.redirectToLoginPageIfAuthRequired(res)) {
        return true;
      }
      if ("non_field_errors" in res) {
        this.$notify({text: res.non_field_errors, type: 'error'});
        return true;
      }
      if ("invalid_fields" in res) {
        let invalidFields = {};
        for(let field in res.invalid_fields) {
          invalidFields[params.apiAttributes[field]] = res.invalid_fields[field];
        }
        this._setCorrectDataInFields(invalidFields, params);
        params.formErrors.setErrors(invalidFields);
        if(params.showErrorModal)
          this.showErrorModal('invalid fields');
        else this.$notify({text: 'Invalid fields', type: 'error'});

      } else {
        if (params.showErrorModal) this.showErrorModal(res.error.message);
      }

      return true;
    },

    _setCorrectDataInFields(invalidFields, params) {
      for(let newField in invalidFields) {
        if( !('nestedErrors' in params) || (params.nestedErrors && !params.nestedErrors[newField] )) {
          // error from backend come in array. Set error as a string
          invalidFields[newField] = invalidFields[newField][0]
          continue
        }
        if('nestedErrors' in params) {
          this._setNestedErrors(invalidFields, params)
        }
      }
    },

    _setNestedErrors(invalidFields, params) {
      let {nestedErrors} = params
      for(let field in nestedErrors) {
        let fieldWithNestedErrors = invalidFields[field]
        fieldWithNestedErrors.forEach(nestedItem => {
          for(let nestedField in nestedItem) {
            let matchedField = nestedErrors[field][nestedField]
            nestedItem[matchedField] = nestedItem[nestedField]
          }
        })
      }
    },

    redirectToLoginPageIfAuthRequired(res, params) {
      if(!params || !params.redirectName) return false
      if (res.error.code !== 401 && res.error.code !== 403) {
        return false;
      }
      router.push({
        name: params.redirectName,
        query: { returnURL: router.currentRoute.name }
      });
      return true;
    },

    showErrorModal(msg, title) {
      EventBus.$emit(EVENT_BUS.DIALOG_ERR, {
        component: DialogErr,
        props: {
          title: title || 'Error',
          msg: msg,
        }
      });
    }
  }
};
