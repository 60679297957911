import CourseModel, { CourseModelDetailed } from './courseModel';

export default class CourseService {
  constructor(api) {
    this.api = api;
  }

  async getCourses(query) {
    try {
      let res = await this.api.getCoursesBy(query)
      return res.map(course => new CourseModel(course))
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async get(ID) {
    try {
      let res = await this.api.get(ID)
      return new CourseModelDetailed(res)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async updateNotes(ID, data) {
    try {
      let res = await this.api.patch(ID, {
        notes: data
      })
      return new CourseModelDetailed(res)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async patchCourse(ID, data) {
    try {
      let res = await this.api.patch(ID, data)
      return new CourseModelDetailed(res)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getCoursesDetailed(query) {
    try {
      let res = await this.api.courseListDetailed(query);
      return res.map(course => new CourseModelDetailed(course));
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getCoursesBy(location) {
    try {
      return await this.getCourses({ city: location });
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getCoursesDetailedBy(location) {
    try {
      return await this.getCoursesDetailed({ city: location })
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getCourseDay(ID) {
    try {
      let res = await this.api.getCourseDay(ID)
      return res
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async createCourseDay(payload) {
    return await this.api.createCourseDay({
      ...payload,
      meeting_space: payload.meeting_space === -1 ? null : payload.meeting_space,
      course_day_sections: payload.course_day_sections.map(section => {
        return {
          session: section.session.id,
          leader: section.leader ? section.leader.id : null
        };
      })
    });
  }

  async updateCourseDay(id, payload) {
    return await this.api.updateCourseDay(id, {
      ...payload,
      course_day_sections: payload.course_day_sections.map(section => {
        return {
          id: section.id ? section.id : null,
          session: section.session.id,
          leader: section.leader ? section.leader.id : null
        };
      })
    });
  }

  async updateCourseDaySections(id, payload) {
    let res = await this.api.patchCourseDay(id, {
      course_day_sections: payload.map(section => {
        return {
          id: section.id ? section.id : null,
          session: section.session.id,
          leader: section.leader ? section.leader.id : null
        };
      })
    });
    return res;
  }

  async removeCourseDay(id) {
    try {
      let res = await this.api.removeCourseDay(id);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async courseDays(query) {
    try {
      let res = await this.api.courseDays(query);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async courseDaysFuture(query, size) {
    try {
      let _query = { ...query, size: size };
      let res = await this.api.courseDaysFuture(_query);
      return {
        results: res.results,
        pagination: {
          count: res.count,
          next: res.next,
          previous: res.previous,
          size: res.size,
          page: res.page
        }
      };
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async courseDaysPast(query, size) {
    try {
      let _query = { ...query, size: size };
      let res = await this.api.courseDaysPast(_query);
      return {
        results: res.results,
        pagination: {
          count: res.count,
          next: res.next,
          previous: res.previous,
          size: res.size,
          page: res.page
        }
      };
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getMeetingSpace(id) {
    try {
      return await this.api.meetingSpaceInfo(id);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateCourseDayZoomID(courseDayID, zoomID) {
    try {
      return await this.api.patchCourseDay(courseDayID, { zoom_webinar_id: zoomID });
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async candidatesZoomRegistrantBulk(courseID, candidatesIDs) {
    try {
      return await this.api.bulkSetZoomRegistrant(courseID, { candidates: candidatesIDs });
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async candidatesZoomAllRegistrantBulk(courseID) {
    try {
      return await this.api.bulkSetZoomAllRegistrant(courseID);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async candidatesGetZoomPresentie(courseID) {
    try {
      return await this.api.candidatesGetZoomPresentie(courseID);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async candidatesSendZoomInvite(courseID, candidatesIDs) {
    try {
      return await this.api.bulkSendZoomInvite(courseID, { candidates: candidatesIDs });
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getChecklists(params) {
    try {
      return await this.api.getChecklists(params);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async checkListLogs(params) {
    try {
      return await this.api.checkListLogs(params);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getChecklist(checklistID) {
    try {
      return await this.api.getChecklist(checklistID);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async createChecklist(params) {
    try {
      return await this.api.createChecklist(params);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateChecklist(checklistID, params) {
    try {
      return await this.api.updateChecklist(checklistID, params);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async changeChecklist(checklistID, params) {
    try {
      return await this.api.changeChecklist(checklistID, params);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async deleteChecklist(checklistID) {
    try {
      return await this.api.deleteChecklist(checklistID);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async uploadCoursePhoto(courseID, data) {
    const config = { headers: { 'content-type': 'multipart/form-data' } };
    const formData = new FormData();
    formData.append('course_photo', data);

    try {
      return await this.api.uploadCoursePhoto(courseID, formData, config);
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
