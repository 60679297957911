<template lang="pug">
  .fill-height
    v-app-bar(app color='primary' dark)
      v-spacer
    v-content.fill-height
      .d-flex.align-center.justify-center.fill-height.mobile-wrap
        router-view
</template>

<script>
export default {
  name: 'AuthLayout',
};
</script>

<style lang="scss" scoped>
.menu-item::v-deep {
  text-transform: capitalize;
}
</style>