import CRMauth from '@/services/CRMinitAuth';

const journeys = query =>
  CRMauth.get(`${process.env.VUE_APP_CRM_API}common/journey_days/`, {
    params: query
  });

const candidateJourneys = query =>
  CRMauth.get(`${process.env.VUE_APP_CRM_API}common/journeys/`, {
    params: query
  });

const createJourney = data =>
  CRMauth.post(`${process.env.VUE_APP_CRM_API}common/journeys/`, data);

const updateJourney = (id, data) =>
  CRMauth.put(`${process.env.VUE_APP_CRM_API}common/journeys/${id}/`, data);

const getJourney = id =>
  CRMauth.get(`${process.env.VUE_APP_CRM_API}common/journeys/${id}/`);

const setPresentie = (journeyID, payload) =>
  CRMauth.post(`${process.env.VUE_APP_CRM_API}common/journey_days/${journeyID}/set_presentie/`, payload);

const removeJourney = id =>
  CRMauth.delete(`${process.env.VUE_APP_CRM_API}common/journeys/${id}/`);

export default {
  journeys,
  candidateJourneys,
  createJourney,
  updateJourney,
  getJourney,
  setPresentie,
  removeJourney
};
