import ROUTE_NAMES from "@/app/admin/router/router-const";

export const DRIVING_SCHOOL_HEADERS_LIST = [
  {text: '#', value: 'id', sortable: false },
  {text: 'Driving School name', value: 'name', sortable: true },
  {text: 'Slug', value: 'slug', sortable: false },
  {text: 'School Number', value: 'number', sortable: false },
  {text: 'Candidates Count', value: 'count', sortable: true },
  {text: 'Amount calculated', value: 'amount', sortable: true },
  {text: 'Count unpaid', value: 'count_unpaid', sortable: true },
  {text: 'Amount unpaid', value: 'amount_unpaid', sortable: true },
  {text: 'Actions', value: '-', sortable: false },
];

export const DRIVING_SCHOOL_HEADERS_APPLICATIONS = [
  {text: '#', value: 'ds-app-index', sortable: false },
  {text: 'Driving school', value: 'driving_school__name', sortable: true },
  {text: 'App ID', value: 'ds-app-appID', sortable: false },
  {text: 'Candidate', value: 'ds-app-candidate', sortable: false },
  {text: 'Course type', value: 'course_type', sortable: true },
  {text: 'Amount calculated', value: 'amount', sortable: true },
  {text: 'Datetime of calculation', value: 'created_at', sortable: true },
  {text: 'Paid', value: 'is_paid', sortable: true }
];

export const DRIVING_SCHOOL_HEADERS_TRANSACTIONS = [
  {text: 'ID', value: 'ds-ts-id', sortable: false },
  {text: 'Datetime of payout', value: 'created_at', sortable: true },
  {text: 'Manager', value: 'ds-ts-manager', sortable: false },
  {text: 'Driving school', value: 'driving_school__name', sortable: true },
  {text: 'Candidates count', value: 'count', sortable: true },
  {text: 'Amount paid', value: 'amount', sortable: true },
  {text: 'Amount calculated', value: 'calculated', sortable: true },
  {text: 'Comment', value: 'ds-ts-comment', sortable: false }
];

export const DRIVING_SCHOOL_TABS = {
  LIST: 'list',
  APPLICATIONS: 'applications',
  TRANSACTIONS: 'transactions',
};

export const DRIVING_SCHOOL_ROUTES = [
  ROUTE_NAMES.DRIVING_SCHOOLS,
  ROUTE_NAMES.DRIVING_SCHOOLS_APPLICATIONS,
  ROUTE_NAMES.DRIVING_SCHOOLS_TRANSACTIONS
];

export const JUMBOTRON_FILTERS = {
  ID: 'id',
  CREATED_AT_AFTER: 'created_at_after',
  CREATED_AT_BEFORE: 'created_at_before',
  DRIVING_SCHOOL: 'driving_school',
  TRANSACTION: 'transaction'
};