import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '@/store';

import authMiddleware from './middleware/middleware-auth';

import Admin from '@/app/admin/router/router-admin';
import Auth from '@/app/auth/core/router-auth';
import ADMIN_ROUTE_NAMES from '@/app/admin/router/router-const';
import ROUTER_NAMES from './router-const';
import NOT_FOUND_PAGE from '@/404.vue';
import SERVER_ERROR_PAGE from '@/500.vue';

if (!process || process.env.NODE_ENV !== 'test') Vue.use(VueRouter);

const MAIN_ROUTE_NAME = 'main_route';

const domainRedirects = {
  'crm.ntcr1.nl': ADMIN_ROUTE_NAMES.CANDIDATES
};

const routes = [
  Admin,
  Auth,
  {
    path: '/',
    name: MAIN_ROUTE_NAME
  },
  {
    path: '/server_error',
    name: ROUTER_NAMES.SERVER_ERROR,
    component: SERVER_ERROR_PAGE
  },
  {
    path: '*',
    name: ROUTER_NAMES.NOT_FOUND,
    component: NOT_FOUND_PAGE
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.name === MAIN_ROUTE_NAME) {
    if (window) {
      const domainRedirect = Object.keys(domainRedirects).find(redirect => window.location.host.includes(redirect))
      if (domainRedirect) {
        next({ name: domainRedirects[domainRedirect] });
      }
    }
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    authMiddleware({ store: store, next: next, router: router });
  } else next();
});

export default router;
