import { CourseModelDetailed } from '@/app/admin/models/courseModel';
import CourseService from '@/app/admin/models/courseService';
import api from '@/services/api/api-courses';

export default {
  namespaced: true,

  state: {
    course: new CourseModelDetailed()
  },

  getters: {
    course: state => state.course,
  },

  mutations: {
    updateCourseNotes(state, val) {
      state.course.notes = val;
    }
  },

  actions: {
    async loadCourse({ state }, ID) {
      let res = await new CourseService(api).get(ID);
      state.course = res;
      return res;
    },
    async updateCourseValue({ state }, data) {
      let res = await new CourseService(api).patchCourse(data.ID, data.data);
      state.course = res;
      return res;
    },
  }
};
