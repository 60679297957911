export const EVENT_BUS = {
  FIELDS: 'fields',
  DIALOG_ERR: 'dialog-err',
  SNACKBAR: 'snackbar',
  CANDIDATE_UPDATED: 'candidate-updated',
  REMOVE_PREVIEW_QUESTION: 'remove-preview-question',
};

export const DEFAULT_COUNT_LIST = [10, 20, 30, 40, 50, 100, 150, 200];

export const DEFAULT_PAGINATION = {
  PAGE: 1,
  SIZE: 50
};

export const DEFAULT_LIMIT_PAGINATION = {
  LIMIT: 30,
  OFFSET: 0
};

export const LOAD_DEFAULT_CITY_ID = 0;

export const DATE_FORMAT_DEFAULT = 'DD-MM-YYYY';
export const DATE_FORMAT_HOUR_DEFAULT = 'DD-MM-YYYY HH:mm';
export const HOUR_FORMAT_DEFAULT = 'HH:mm';
export const DATE_DEFAULT_REGXP = /^([0-2][0-9]|(3)[0-1])(-)(((0)[0-9])|((1)[0-2]))(-)\d{4}$/;
export const HOUR_DEFAULT_REGXP = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
export const DATE_FORMAT_CRM_BACKEND = 'YYYY-MM-DD';
export const DATE_FORMAT_HOUR_SECONDS_DEFAULT = 'DD-MM-YYYY HH:mm:ss';
export const TWO_WEEKS_IN_MILLISECONDS = 12096e5;

export const LOG_STATUS = {
  DEFAULT: 'default',
  SUCCESS: 'success',
  ERROR: 'error'
};

export const LOG_PAGE = {
  ACCEPTANCE: 'acceptance',
  RESERVATIONS: 'reservations',
  EXAM_RESULTS: 'exam_results',
  IMPORT_TOP_CBR: 'import_top_cbr',
  MIJN_CBR_SLOTS: 'mijn_cbr_slots',
  TOP_CBR_SLOTS: 'top_cbr_slots',
  CONTANCT: 'candidate_contact',
  CHANGE_LOG: 'change_log'
};

export const PRODUCT = {
  BTH: 'BTH',
  BTH_VE: 'BTH-VE'
};

export const MONTH_DUTCH = ['Januari', 'Februari', 'Maart', 'April', 'Mei', 'Juni', 'Juli', 'Augustus', 'September', 'Oktober', 'November', 'December'];
export const DOW = ['Sunday', 'Monday', 'Tuesday', 'Wednesday','Thursday' , 'Friday' , 'Saturday'];
export const DOW_DUTCH = ['Zondag', 'Maandag', 'Dinsdag', 'Woensdag','Donderdag' , 'Vrijdag' , 'Zaterdag'];
export const DOW_DUTCH_DDD = ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za'];

export const DOW_BACKEND_ITEM_KEY = [
  {
    key: 2,
    label: 'Monday'
  },
  {
    key: 3,
    label: 'Tuesday'
  },
  {
    key: 4,
    label: 'Wednesday'
  },
  {
    key: 5,
    label: 'Thursday'
  },
  {
    key: 6,
    label: 'Friday'
  },
  {
    key: 7,
    label: 'Saturday'
  },
  {
    key: 0,
    label: 'Sunday'
  },
];

export const CRM_PERMS = {
  CANDIDATES: 'CANDIDATES',
  RESULTS: 'RESULTS',
  SLOTS: 'SLOTS',
  CBR_UPDATES: 'CBR_UPDATES',
  CBR_ACCOUNTS: 'CBR_ACCOUNTS',
  COURSE_MANAGEMENT: 'COURSE_MANAGEMENT',
  //
  DRIVING_SCHOOLS: 'DRIVING_SCHOOLS',
  DRIVING_SCHOOLS_CAN_ADD_PAYOUT: 'ADD_DS_PAYOUT',
  //
  REFUND: 'REFUND',
  APPLICATIONS_TASK_LIST: 'APPLICATIONS_TASK_LIST',
  REAPPLY_TASK_LIST: 'REAPPLY_TASK_LIST',
  FEEDBACK: 'FEEDBACK',
  COURSE_TASKS: 'COURSE_TASKS',
  MERGE_CANDIDATES: 'MERGE_CANDIDATES',
  //
  COURSES: 'COURSES',
  COURSES_LEADERS: 'COURSES_LEADERS',
  //
  ADD_REAPPLY: 'ADD_REAPPLY', // button for change reapply state
  EDIT_ONLINE: 'EDIT_ONLINE' // button for edit online access
};

export const STORAGE = {
  SUGGEST: {
    EXAM: {
      CONTENT: 'suggest_exam_content',
      SUBJECT: 'suggest_exam_subject'
    },
    EXCHANGE: {
      CONTENT: 'suggest_exchange_content',
      SUBJECT: 'suggest_exchange_subject'
    },
    MIJN: {
      CONTENT: 'suggest_mijn_content',
      SUBJECT: 'suggest_mijn_subject',
    }
  }
};

export const COLOR_PAGE = {
  CANDIDATES: 'CANDIDATES',
  EXAMS: 'EXAMS'
};

export const PACKAGES_TYPES = {
  PLAN_TYPE_PUBLIC: 'PUBLIC',
  PLAN_TYPE_OFFLINE: 'OFFLINE',
  PLAN_TYPE_REAPPLY: 'REAPPLY',
  PLAN_TYPE_EXTERNAL: 'EXTERNAL',
  PLAN_TYPE_FREE: 'FREE'
};

export const DEFAULT_TIME = ['13:00', '13:20', '13:40', '14:00', '14:20', '14:40', '15:00', '15:20', '15:40'];
export const START_DEFAULT_TIME = ['06:30', '06:45', '07:00', '07:15', '07:30', '07:45', '08:00', '08:15', '08:30', '08:45', '09:00', '09:15'];
export const END_DEFAULT_TIME = ['13:00', '13:15', '13:30', '13:45', '14:00', '14:15', '14:30', '14:45', '15:00', '15:15', '15:30', '15:45', '16:00', '16:15', '16:30'];
export const CBR_SLOTS_TIME = ['08:40', '09:00', '09:20', '09:40', '10:00', '10:20', '10:40', '11:00', '11:20', '11:40', '12:00', '12:20', '12:40', '13:00', '13:20', '13:40', '14:00', '14:20', '14:40', '15:00', '15:20', '15:40'];

export const FEATURE_FLAG = {
  NCRM_3360: 'NCRM_3360', // https://enkonix.atlassian.net/browse/NCRM-3360 => Zoom functionality
  NCRM_6461: 'NCRM_6461', // https://enkonix.atlassian.net/browse/NCRM-6461 => Hide tab by leaders
  NCRM_6506: 'NCRM_6506', // https://enkonix.atlassian.net/browse/NCRM_6506 => Course image func
};

export const SWITCH_FLAG = {};

export const COURSE_TYPES_LIST = [
  {key: 'offline', name: 'Offline'},
  {key: 'online', name: 'Online'}
];

export const ERRORS_MESSAGES = {
  REQUIRED: 'Dit veld is verplicht',
  INPUT_BIRTHDAY: 'Voer een geboortedatum in',
  DATE_IS_PAST: 'De gekozen datum is in het verleden',
  NOT_VALID_DATE: 'De gekozen datum is incorrect',
  NOT_VALID_EMAIL: 'E-mail is niet geldig',
  CYRILLIC_TEXT: 'Dit veld is incorrect',
  EMOJI_TEXT: 'Dit veld is incorrect',
  ERROR_FIELD: 'Dit veld is incorrect'
};

export const COURSE_LOCATION_ONLINE = {
  ID:6,
  code:"ON",
  name:"Online"
};

export const LicenseTypesEnum = Object.freeze({
  AUTO: 'auto',
  SCOOTER: 'scooter',
  MOTOR: 'motor'
});

export const LICENSE_TYPES = [
  { id: 1, name: 'Autotheorie', slug: LicenseTypesEnum.AUTO },
  { id: 3, name: 'Motortheorie', slug: LicenseTypesEnum.MOTOR },
  { id: 2, name: 'Scootertheorie', slug: LicenseTypesEnum.SCOOTER },
];