<template lang="pug">
  div.base-snack.text-center {{ msg }}
</template>

<script>
export default {
  props: {
    title: String,
    msg: [String, Object]
  },
  methods: {
    closeModal() {
      this.$emit('onClose')
    }
  }
}
</script>

<style lang="scss" scoped>
.base-snack {
  width: 100%;
}
</style>