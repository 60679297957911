import { convertToDefaultDateFormat, truncateString, formatingToDDD, getDOWDutchDDD } from '@/util';
import CMExamConfig, { CMOverviewExamConfigDecorator } from '../modules/course_managment/classes/CMExamConfig';

function getDateAndName(_date, date, name) {
  if (name && date) return `${ getDOWDutchDDD(new Date(_date).getDay()) } ${ date } - ${ truncateString(name, 40) }`;
  else if (name) return `${ truncateString(name, 40) }`;
  return `${ formatingToDDD(date) } ${ date }`;
}

function getCourseLabel(_date, date, name) {
  if (name && date) return `${ getDOWDutchDDD(new Date(_date).getDay()) } ${ date } - ${ truncateString(name, 40) }`;
  else if (name) return `${ truncateString(name, 40) }`;
  return `${ formatingToDDD(date) } ${ date }`;
}

export default class CourseModel {
  constructor(item = {}) {
    this.ID = item.id;
    this._date = item.date;
    this.date = convertToDefaultDateFormat(item.date);
    this.city = item.city;
    this.name = item.name;
    this.candidatesCount = item.candidates_count;
    this.topCbrReserveQueueCount = item.top_cbr_reserve_queue_count;
    this.topCbrExchangeQueueCount = item.top_cbr_exchange_queue_count;
    this.mijnCbrQueueCount = item.mijn_cbr_queue_count;
    this.will_come_candidates_count = item.will_come_candidates_count;
    this.will_not_come_candidates_count = item.will_not_come_candidates_count;
    this.exams = item.exams && item.exams.length ? item.exams.map(item => new CMExamConfig(item)) : [];
    this.zoom_webinar_id = item.zoom_webinar_id;
    this.reservable_license_types = item.reservable_license_types;
  }

  get originDate() {
    return this._date;
  }

  get dateAndName() {
    return getDateAndName(this._date, this.date, this.name);
  }
}

export class CourseModelDetailed {
  constructor(item = {}) {
    this.ID = item.id;
    this._date = item.date;
    this.date = item.date ? convertToDefaultDateFormat(item.date) : '';
    this.city = item.city;
    this.candidatesCount = item.candidates_count;
    this.topCbrReserveQueueCount = item.top_cbr_reserve_queue_count;
    this.topCbrExchangeQueueCount = item.top_cbr_exchange_queue_count;
    this.mijnCbrQueueCount = item.mijn_cbr_candidates_count;
    this.exams = item.exams && item.exams.length ?
      item.exams.map(item => new CMOverviewExamConfigDecorator(new CMExamConfig(item)).convert()) : [];
    this.maxStudents = item.max_students;
    this.slotsCount = item.slots_count;
    this.notes = item.notes;
    this.name = item.name;
    this.isVisibleOnLanding = item.is_visible_on_landing;
    this.will_come_candidates_count = item.will_come_candidates_count;
    this.will_not_come_candidates_count = item.will_not_come_candidates_count;
    this.has_candidates_needs_new_payment = item.has_candidates_needs_new_payment;
    this.candidates_course_not_paid_count = item.candidates_course_not_paid_count;
    this.zoom_webinar_id = item.zoom_webinar_id;
    this.reservable_license_types = item.reservable_license_types;
    this.autoCounts = item.auto_counts;
    this.motorCounts = item.motor_counts;
    this.scooterCounts = item.scooter_counts;
  }

  get originDate() {
    return this._date;
  }

  get dateAndName() {
    return getDateAndName(this._date, this.date, this.name);
  }
}

export class CourseEditable {
  constructor(item = {}) {
    this.city = item.city;
    this.date = item.date || '';
    this.notes = item.notes || '';
    this.isVisibleOnLanding = item.isVisibleOnLanding || null;
    this.maxStudents = item.max_students || 0;
    this.name = item.name;
    this.rangeMode = item.rangeMode || null;
    this.reservableLicenseTypes = item.reservable_license_types || [];
  }
}

export class CourseMini {
  constructor(item = {}) {
    this.item = item;
  }

  get() {
    return {
      ...this.item,
      label: this.getLabel()
    };
  }

  getLabel() {
    let dateFormatted = convertToDefaultDateFormat(this.item.date);
    if (this.item.name && this.item.date)
      return `${ getDOWDutchDDD(new Date(this.item.date).getDay()) } ${ dateFormatted } ${ this.item.city } ${ truncateString(this.item.name, 40) }`;
    else if (this.item.name) return `${ this.item.city } ${ truncateString(this.item.name, 40) }`;
    return `${ getDOWDutchDDD(new Date(this.item.date).getDay()) } ${ dateFormatted } ${ this.item.city }`;
  }
}
