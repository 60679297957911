import CoursesAPI from '@/services/api/api-courses';

export default {
  namespaced: true,

  state: () => ({
    items: []
  }),

  getters: {
    items: state => state.items
  },

  mutations: {
    set(state, items) {
      state.items = items.map(location => {
        return {
          ...location,
          textToShow: `${location.name}`
        };
      });
    },

    clearItems(state) {
      state.items = [];
    },
  },

  actions: {
    async list({state, commit}, query) {
      try {
        if (!query.main_city) return commit('set', []);
        if (query && query.cache && state.items) return state.items;
        let res = await CoursesAPI.meetingSpacesList({ main_city: query.main_city });
        commit('set', res);
        return res;
      } catch (error) {
        console.log(error, 'meetingSpaces');
        return Promise.reject(error);
      }
    }
  }
};
