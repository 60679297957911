import CMService from '@/app/admin/modules/course_managment/core/cm-service'

const svc = new CMService();

export default {
  namespaced: true,

  state: () => ({
    courseListDetailed: null,
    activeCourses: [],
    isMultipleMode: false,
  }),

  getters: {
    activeSingleCourse: state => state.activeCourses.length === 1 ? state.activeCourses[0] : {},
    activeCourses: state => state.activeCourses
  },

  mutations: {
    setMultipleMode(state) {
      state.isMultipleMode = true
    },

    setSingleMode(state) {
      state.isMultipleMode = false
    },

    setCourseListDetailed(state, items) {
      state.courseListDetailed = items
    },

    selectCourse(state, item) {
      item.select()
      if (!state.activeCourses.map(c => c.ID).includes(item.ID)) {
        state.activeCourses.push(item);
      }
    },

    setCourses(state, items) {
      state.activeCourses.forEach(course => course.unselect())
      if (items.length) {
        items.forEach(item => item.select())
      }
      state.activeCourses = items
    },

    unSelectCourse(state, item) {
      item.unselect()
      state.activeCourses = state.activeCourses.filter(course => course.ID !== item.ID);
    },

    unSelectAllCourses(state) {
      state.activeCourses = []
    },

    updateCourseNote(state, {note, courseId}) {
      const activeCourse = state.courseListDetailed.find(course => course.ID === courseId)
      activeCourse.notes = note
    },

    clearAll(state) {
      state.courseListDetailed = null
      state.activeCourses.forEach(course => course.unselect())
    },

    clearActiveCourse(state) {
      state.activeCourses = []
    }
  },

  actions: {
    async courseListDetailed({commit}, params) {
      try {
        let res = await svc.courseList(params.query)
        commit('setCourseListDetailed', res)
        return res
      } catch (error) {
        return Promise.reject(error)
      }
    },
  }
};
