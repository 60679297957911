import { dateFormating, convertToDefaultDatetime, convertToDefaultDateFormat } from "@/util"
import { DeleteMS } from "@/util/filters"

export default class CMLocationConfigModel {
  constructor(item = {
    name: '',
    gather_time: '',
    course_start_time: '',
    course_end_time: '',
    cbr_location: '',
    exam_time: '',
    transport_time: '',
    first_day_course_date: null,
    first_day_course_start_time: null,
    first_day_course_end_time: null,
    is_independent_exam: false,
    id: null
  }) {
    this.id = item.id
    this.name = item.name
    this.gather_time = item.gather_time
    this.course_start_time = DeleteMS(item.course_start_time) || ''
    this.course_end_time = DeleteMS(item.course_end_time) || ''
    this.cbr_location = item.cbr_location
    this.route_from = item.route_from
    this.route_link = item.route_link
    this.postal_code = item.postal_code
    this.exam_time = DeleteMS(item.exam_time) || ''
    this.transport_type= item.transport_type
    this.first_day_course_date = convertToDefaultDateFormat(item.first_day_course_date) || null
    this.first_day_course_start_time = DeleteMS(item.first_day_course_start_time) || null
    this.first_day_course_end_time = DeleteMS(item.first_day_course_end_time) || null
    this.is_independent_exam = item.is_independent_exam || false
  }

  merge(item) {
    this.id = item.id
    this.name = item.name
    this.gather_time = item.gather_time
    this.course_start_time = DeleteMS(item.course_start_time) || ''
    this.course_end_time = DeleteMS(item.course_end_time) || ''
    this.cbr_location = item.cbr_location
    this.route_from = item.route_from
    this.route_link = item.route_link
    this.postal_code = item.postal_code
    this.exam_time = DeleteMS(item.exam_time) || ''
    this.transport_type= item.transport_type
    this.first_day_course_date = convertToDefaultDateFormat(item.first_day_course_date) || null
    this.first_day_course_start_time = DeleteMS(item.first_day_course_start_time) || null
    this.first_day_course_end_time = DeleteMS(item.first_day_course_end_time) || null
    this.is_independent_exam = item.is_independent_exam || false
  }
}

export class CMLocationApiData {
  constructor(data) {
    this.data = data
  }

  convert() {
    return {
      ...this.data,
      first_day_course_date: this.data.first_day_course_date ? dateFormating(this.data.first_day_course_date).join('-') : null,
      is_independent_exam: this.data.is_independent_exam || false
    }
  }
}
