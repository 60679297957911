export default {
  namespaced: true,

  state: () => ({
    items: null
  }),

  getters: {
    items: state => state.items,
  },

  mutations: {
    set(state, items) {
      state.items = items;
    },
  },

  actions: {
    async list({ commit }, params) {
      try {
        let _length = params.length || 5;
        let list = [];

        for (let step = 1; step <= _length; step++) {
          list.push({ id: step, label: step });
        }

        list.reverse();

        commit('set', list);
        return list;
      } catch (error) {
        return Promise.reject(error);
      }
    }
  }
};
