import ReviewsAPI from '@/services/api/landing/api-reviews';

export default {
  namespaced: true,

  state: () => ({
    items: {}
  }),

  getters: {
    items: state => state.items ? state.items : {}
  },

  mutations: {
    set(state, items) {
      state.items = items;
    }
  },

  actions: {
    async list({ state, commit }) {
      try {
        let res = await ReviewsAPI.choices();
        commit('set', res);
        return res;
      } catch (error) {
        console.log(error);
        return Promise.reject(error);
      }
    },
  }
};