import api from '@/services/api/api-featureFlags';

export default {
  namespaced: true,

  state: () => ({
    items: {}
  }),

  getters: {
    items: state => state.items
  },

  mutations: {
    setItems(state, items) {
      state.items = items;
    }
  },

  actions: {
    async load({ state, commit }) {
      try {
        let res = await api.featureFlags();
        commit('setItems', res);
        return res;
      } catch (error) {
        return Promise.reject(error);
      }
    }
  }
};
