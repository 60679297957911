import auth from "@/services/CRMinitAuth"

const get = data => {
  return auth.post(`${process.env.VUE_APP_CRM_API}shared_storage/get/`, data);
}

const set = data => {
  return auth.post(`${process.env.VUE_APP_CRM_API}shared_storage/set/`, data);
}

export default {
  get,
  set
}