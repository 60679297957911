<template lang="pug">
  .main(:class="{'full-width': isPageWithoutSidebar(this.$route.name) && isMobile && isCourseLeadersAccess}")
    sidebar-mobile(v-if="isCourseLeadersAccess && isPageWithoutSidebar(this.$route.name) && isMobile")
    sidebar(v-else)
    main(:class="['page-' + $route.name.toLowerCase()]").app-main
      toolbar
      router-view
    portal-target(name="root" multiple)
</template>

<script>
import Vue from 'vue'
import store from '@/store'
import { mapMutations, mapActions } from 'vuex'
import RouterNames, { AFFILIATES } from './router/router-const'

import auth from '@/services/CRMinitAuth'
import CrmPerms from '@/app/admin/models/crmPerms'
import toolbar from '@/components/global/Toolbar.vue'
import sidebar from '@/components/global/sidebar/Sidebar.vue'
import sidebarMobile from '@/components/global/sidebar/SidebarMobile.vue'
import { isMobile } from '@/util'
import { CRM_PERMS } from '@/util/const'


export const state = Vue.observable({
  perms: null
})

export default {
  name: 'DefaultLayout',

  async beforeRouteEnter(to, from, next) {
    try {
      const [currentUser] = await Promise.all([await store.dispatch('user/current'), await store.dispatch('featureFlags/load')])
      state.perms = new CrmPerms({
        superUser: currentUser.crm_full_access,
        avaliablePerms: currentUser.crm_permissions
      })
      if (!currentUser.crm_permissions) {
        next()
        return
      }
      if (state.perms.has(to.name) || state.perms.has(to.meta.perms)) {
        next()
        return
      }
      if (AFFILIATES[to.name] && currentUser.crm_permissions.includes(AFFILIATES[to.name])) {
        next()
        return
      }
      next()
      return
    } catch (res) {
      console.error(res)
      if (res.error.code === 401 || res.error.code !== 403) {
        next({
          name: RouterNames.LOGIN_CRM,
          query: {returnURL: to.name}
        });
        return
      }
      next({name: RouterNames.SERVER_ERROR})
    }
  },

  data: () => ({
    perms: state.perms,
    isMobile: isMobile()
  }),

  mounted() {
    this.loadCourseCities({cache: true})
    this.loadCourseCitiesWidthAdditionalInfo()
  },

  computed: {
    permissions() {
      if(!state.perms) return false
      return state.perms.avaliablePerms;
    },

    hasCourseLeadersPermissions() {
      if (!this.permissions) return false;
      return !!this.permissions.includes(CRM_PERMS.COURSES_LEADERS);
    },

    hasCoursesPermissions() {
      if (!this.permissions) return false;
      return !!this.permissions.includes(CRM_PERMS.COURSES);
    },

    isCourseLeadersAccess() {
      return this.hasCourseLeadersPermissions && this.permissions.length === 1
    },
  },

  methods: {
    ...mapMutations({
      clearCurrentUser: 'user/clear'
    }),

    ...mapActions({
      loadCourseCities: 'crmCourseCities/list',
      loadCourseCitiesWidthAdditionalInfo: 'crmCourseCities/listWithAdditionalInfo'
    }),

    async logout() {
      auth.logout()
      this.clearCurrentUser()
      this.redirectToAuth()
    },

    redirectToAuth() {
      this.$router.push({name: this.$ROUTER_NAMES.LOGIN_CRM})
    },

    isPageWithoutSidebar(page) {
      return [this.$ROUTER_NAMES.COURSES, this.$ROUTER_NAMES.COURSE_LEADERS,  this.$ROUTER_NAMES.CANDIDATES].includes(page)
    }
  },

  provide() {
    return {
      logout: this.logout,
      perms: state.perms
    }
  },

  components: {
    sidebar,
    sidebarMobile,
    toolbar
  }
};
</script>

<style lang="scss" scoped>
.main {
  position: relative;
  background-color: $layout-bg;
}

.menu-item::v-deep {
  text-transform: capitalize;
}

</style>

<style lang="scss">

.app-main {
  margin-left: 49px;
  padding-top: 48px;

  .header {
    position: absolute;
    top: 0;
    left: 48px;
    z-index: 9;
    width: calc(100vw - 48px);
    background-color: white;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
  }
}
.main.full-width {
  .header {
    left: 0;
    width: 100vw;
  }

  .app-main {
    margin-left: 0;
    .cd-sidebar {
      width: 100%;
    }
  }
}

</style>
