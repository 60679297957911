import ROUTE_NAMES from '@/app/admin/router/router-const';
import {LicenseTypesEnum} from "@/util/const";

export const FILTERS_ARGS = {
  ID: 'id',
  SEARCH: 'search',
  CITY: 'exam_location',
  CITY_COURSE: 'city',
  QUEUE_EXAM_LOCATION: 'queue_exam_location',
  CBR_USER: 'cbr_user',
  CBR_STATUS: 'cbr_status',
  EXAM_PRODUCT: 'product_name',
  LICENSE_TYPE: 'license_type',
  FAILED_EXACT: 'failed__exact',
  QUEUE_EXAM_DATE_AFTER: 'queue_exam_date_after',
  QUEUE_EXAM_DATE_BEFORE: 'queue_exam_date_before',
  EXAM_DATE_AFTER: 'exam_date_after',
  EXAM_DATE_BEFORE: 'exam_date_before',
  EXAM_TIME_AFTER: 'exam_time_after',
  EXAM_TIME_BEFORE: 'exam_time_before',
  LABEL: 'labels',
  LABEL_EXCLUDE: 'labels_exclude',
  CANDIDATE_PAGE: 'candidate_page',
  HAS_AVALIABLE_SLOTS: 'has_available_slots',
  EXAM_REQUESTS_COUNT: 'exam_requests_count',
  MAIN_DATE_AFTER: 'main_date_after',
  MAIN_DATE_BEFORE: 'main_date_before',
  CUSTOM_LABELS: 'custom_labels',
  CUSTOM_LABELS_EXCLUDE: 'custom_labels_exclude',
  COURSE_DATE_AFTER: 'course_date_after',
  COURSE_DATE_BEFORE: 'course_date_before',
  QUEUE_EXAM_DATE_EXCLUDE_AFTER: 'queue_exam_date_exclude_after',
  QUEUE_EXAM_DATE_EXCLUDE_BEFORE: 'queue_exam_date_exclude_before',
  QUEUE_EXAM_DATE_STRICT: 'queue_exam_date_strict',
  QUEUE_TIME_BEFORE: 'queue_time_before',
  QUEUE_TIME_AFTER: 'queue_time_after',
  LAST_ERROR: 'last_error',
  IS_COURSE_PAID: 'is_course_paid',
  IS_NOT_PAID_ONLINE: 'is_not_paid_online',
  COURSE_TYPE: 'course_type',
  FIXED_DATES: 'are_dates_fixed'
};

export const CANDIDATE_PAGE = {
  TASKS: 'tasks',
  WAIT: 'wait',
  NEED_AUTHORIZATION: 'need_authorization',
  CANCELED_LIST: 'canceled_list',
  RESERVE_EXCHANGE: 'reserve_exchange',
  RESERVED_EXCHANGED: 'reserved_exchanged',
  PROCESSED: 'processed',
  RESERVED_ALL: 'all_reserved',
  RESERVED_ALL_WILL_COME: 'all_will_come',
  RESERVED_ALL_WILL_NOT_COME: 'all_will_not_come',
  MIJN_CBR_QUEUE: 'mijn_cbr_queue',
  TOP_CBR_QUEUE: 'top_cbr_queue',
  TOB_CBR_EXCHANGE_QUEUE: 'top_cbr_exchange_queue',
  TOB_CBR_RESERVE_QUEUE: 'top_cbr_reserve_queue',
  ALL: 'all',
};

export const SORT_ARGS = {
  IN_QUEUE: 'is_in_queue',
  FAILED: 'failed',
  ON_HOLD: 'is_on_hold',
  ORDERING: 'ordering',
  NAME: 'name',
  BIRTHDAY: 'date_of_birth',
  STATUS: 'cbr_status',
  CBR_ACCOUNT: 'cbr_user',
  PRODUCT_NAME: 'product_name',
  RESERVED_INFO: 'exam_datetime',
  EXAM_LOCATION: 'exam_location',
  COLOR: 'color',
  NUMBER: 'number',
  EXAM_RESULT: 'exam_result',
  PRESENTIE_STATUS: 'presentie_status',
  ZOOM_STATUS: 'zoom_status',
  // custom
  JOURNEY_DAY: 'journey_day',
  ONLINE_ACCESS_DAYS: 'online_access_days_left',
  WIKI_ACCESS_DAYS: 'wiki_access_days_left'
};

export const CBR_STATUS = {
  NEW: 'new',
  SUBMITED: 'submitted',
  ACCEPTED: 'accepted',
  RESERVED: 'reserved',
  ARCHIVED: 'archived'
};

export const CBR_STATUS_OPTIONS = [
  CBR_STATUS.NEW,
  CBR_STATUS.SUBMITED,
  CBR_STATUS.ACCEPTED,
  CBR_STATUS.RESERVED,
  CBR_STATUS.ARCHIVED
];

export const LICENSE_TYPE_OPTIONS = [
  LicenseTypesEnum.AUTO,
  LicenseTypesEnum.MOTOR,
  LicenseTypesEnum.SCOOTER,
];

export const FREE_STATUSES = [CBR_STATUS.NEW, CBR_STATUS.ARCHIVED];

export const QUEUE_OPTIONS = [
  {value: null, label: 'All'},
  {value: true, label: 'Yes'},
  {value: false, label: 'No'}
];

export const FAILED_OPTIONS = [
  {value: null, label: 'All'},
  {value: true, label: 'Yes'},
  {value: false, label: 'No'}
];

export const ON_HOLD_OPTIONS = [
  {value: null, label: 'All'},
  {value: true, label: 'Yes'},
  {value: false, label: 'No'}
];

export const DEFAULT_BOOLEAN_OPTIONS = [
  {value: null, label: 'All'},
  {value: true, label: 'Yes'},
  {value: false, label: 'No'}
];

export const LABELS = {
  ON_HOLD: 'is_on_hold',
  NO_DATES: 'no_dates',
  NO_CANDIDATE_NUMBER: 'no_candidate_number',
  RESERVATION_ERROR: 'reservation_error',
  ACCEPTANCE_ERROR: 'acceptance_error',
  EXCHANGE_ERROR: 'exchange_error',
  IN_QUEUE: 'is_in_queue',
  IS_IN_EXCHANGE_QUEUE: 'is_in_exchange_queue',
  NEW_RESERVE: 'new_reserve',
  NEW_EXCHANGE: 'new_exchange',
  IS_MIJN_RESERVATION: 'is_mijn_reservation',
  NEED_APPROVAL: 'needs_approval',
  HAS_APPROVAL: 'has_approval'
};

export const SET_MIJN_ARGS = {
  DATETIME: 'datetime',
  LOCATION: 'location',
  PRODUCT_NAME: 'product_name',
  COURSE: 'course_id'
};

export const BULK_MIJN = {
  LOCATION: 'location',
  COURSE: 'course_id',
  EXAM: 'exam'
};

export const ORIGINS = {
  APPLICATION: 'APPLICATION',
  ADDED_MANUALLY: 'ADDED_MANUALLY',
  CONFIRMED_SUGGESTION: 'CONFIRMED_SUGGESTION',
  QUICK_RESERVE: 'QUICK_RESERVE'
};

export const QUICK_FILTER_ID = 'quick_filter_id';

export const SUGGEST_EXAM_TEMPLATE = `We zijn druk bezig om op korte termijn een cursus met examen voor jullie te regelen. We begrijpen dat iedereen zo snel mogelijk weer aan de slag wilt en zijn theorie wil halen. Op dit moment zijn er examens beschikbaar gekomen. Voordeel hiervan is dat het plekken op korte termijn betreffen. Er zijn maar een beperkt aantal examens beschikbaar bij het CBR, dus indien je deze data wilt geef het dan zo spoedig mogelijk door. Let op: op = op.`
export const SUGGEST_EXAM_SUBJECT = `Data nu beschikbaar`;

export const SUGGEST_MIJN_TEMPLATE = `Er zijn momenteel een beperkt aantal examens vrijgekomen die op dezelfde dag zijn als onze cursussen. Je kunt deze direct reserveren. Het CBR heeft in verband met maatregelen voor Covid-19 een beperkte examen capaciteit. Houd er rekening mee dat ze erg snel opraken. Deze examens zijn beschikbaar via www.mijncbr.nl. Indien deze datum schikt kun je de reservering direct in orde maken. Zijn de examens niet meer beschikbaar of schikt de datum niet, dan laten we je op je voorkeursdagen staan en zullen we je opnieuw berichten als er een nieuwe optie komt. Het CBR geeft nog nieuwe examens vrij, deze kunnen ook op een eerdere datum zijn. Als er examens vrijkomen op een van je voorkeursdagen dan zullen wij deze proberen te reserveren. Let op: reserveer alleen een examen op de datum en tijden die we hieronder hebben aangegeven.`
export const SUGGEST_MIJN_SUBJECT = `CBR-examen enkel via Mijn CBR beschikbaar`;

export const API_ATTRIBUTES = {
  number: 'candidateNumber',
  date_of_birth: 'candidateBirthday',
  cbr_user: 'accountUsername',
  cbr_status: 'status',
  exam: 'exam',
  exam_reservation_requests: 'requests',
  phone: 'candidateTel',
  failed: 'failed',
  was_exchanged: 'wasExchanged',
  notes: 'notes',
  is_wait_list: 'isWaitList',
  last_error: 'lastError',
  is_in_queue: 'inQueue',
  is_in_exchange_queue: 'inExchangeQueue',
  no_dates: 'noDates',
  no_candidate_number: 'noCandidateNumber',
  acceptance_error: 'acceptanceError',
  reservation_error: 'reservationError',
  exchange_error: 'exchangeError',
  new_reserve: 'newReserve',
  new_exchange: 'newExchange',
  product_name: "productName",
  labels: 'labels',
  is_mijn_reservation: "isMijnReservation",
  sent_email: 'sentEmails',
  payments: 'payments',
  email: 'candidateEmail',
  course_id: 'courseDate',
  canceled: 'canceled',
  wiki_access_days: 'wiki_access_days',
  wiki_access_from_date: 'wiki_access_from_date',
  reservation_days_offset: 'reservation_days_offset',
  zoom_status: 'zoom_status'
};

export const API_ATTRIBUTES_NESTED = {
  requests: {
    course_exam_id: 'exam',
    exam_date: 'examDate',
    location: 'location',
    comment: 'comment',
    course_id: 'courseDate',
    is_on_hold: 'onHold',
    exam_comment: 'examComment'
  }
};

export const REFUNDED_STATUS = 'refunded';

export const CANDIDATES_TABS = [ROUTE_NAMES.CANDIDATES_TASKS, ROUTE_NAMES.CANDIDATES_ALL, ROUTE_NAMES.CANDIDATES_PROCESSED, ROUTE_NAMES.CANDIDATES_WAIT, ROUTE_NAMES.CANDIDATES_RESERVE_EXCHANGE, ROUTE_NAMES.CANDIDATES_RESERVED_EXCHANGED];

export const CANDIDATES_TABS_LIST = {
  TASKS: ROUTE_NAMES.CANDIDATES_TASKS,
  WAIT: ROUTE_NAMES.CANDIDATES_WAIT,
  NEED_AUTHORIZATION: ROUTE_NAMES.NEED_AUTHORIZATION,
  RESERVE_EXCHANGE: ROUTE_NAMES.CANDIDATES_RESERVE_EXCHANGE,
  RESERVED_EXCHANGED: ROUTE_NAMES.CANDIDATES_RESERVED_EXCHANGED,
  PROCESSED: ROUTE_NAMES.CANDIDATES_PROCESSED,
  CANCEL: ROUTE_NAMES.CANDIDATES_CANCEL,
  ALL: ROUTE_NAMES.CANDIDATES_ALL
};

export const CANDIDATES_HEADERS = [
  {text: '', value: 'edit', class: 'app-table__h_small cell-btns', sortable: false},
  {text: '№', value: 'number', class: 'app-table__h_small cell-index ', align: 'left', sortable: false},
  {text: 'Color', value: 'color', align: 'left', class: 'nowrap cell-placed-paid', sortable: true},
  {text: 'Placed/Paid', value: 'placed', align: 'left', class: 'nowrap cell-placed-paid', sortable: false},
  {text: 'Name', value: 'candidateName', align: 'left', class: 'cell-name', sortable: true},
  {text: 'CDN', align: 'left', sortable: false, value: 'candidateNumber', class: 'nowrap cell-number'},
  {text: 'DOB/Phone', value: 'candidateBirthday', class: 'cell-bday', align: 'center'},
  {text: 'CBR', value: 'accountUsername', align: 'left', class: 'cell-cbr', sortable: true},
  {text: 'App ID', value: 'appId', align: 'center', class: 'cell-appid text-center', sortable: false},
  {text: 'Payment', value: 'payment', sortable: false, class: 'cell-payment'},
  {text: 'Reserved Info', value: 'reserved', align: 'center', class: 'cell-reserved', sortable: true},
  {text: 'Exchange count', value: 'exchange_count', align: 'center', class: 'cell-exchange-count', sortable: false},
  {text: 'License type', value: 'licenseType', align: 'left', class: 'cell-product', sortable: false},
  {text: 'Product', value: 'productName', align: 'left', class: 'cell-product', sortable: true},
  {text: 'Status', value: 'status', align: 'left', class: 'cell-status'},
  {text: '', value: 'delete', align: 'left', class: 'cell-delete'},
  {text: 'Canceled', value: 'canceled', align: 'left', class: 'cell-canceled'},
  {text: 'Last error', value: 'lastError', align: 'center', class: 'nowrap cell-error', sortable: false},
  {text: 'Course type', value: 'course-type', align: 'left', class: 'nowrap cell-course-type', sortable: false},
  {text: 'Package', value: 'package', align: 'left', class: 'nowrap cell-package', sortable: false},
  {text: 'Online from now', value: 'online_access_days_left', align: 'left', class: 'cell-duration', sortable: true},
  {text: 'Wiki from now', value: 'wiki_access_days_left', align: 'left', class: 'cell-duration', sortable: true},
  {text: '', value: 'bov', align: 'center', class: 'nowrap cell-email', sortable: false},
  {text: 'Confirmed Courses', value: 'mainDate', align: 'left', class: 'cell-dates', sortable: false},
  {text: 'Reservation dates', value: 'requests', align: 'left', class: 'cell-reservation', sortable: false},
  {text: 'Note', value: 'note', align: 'center', sortable: false, class: 'cell-note'}
];

export const SYSTEM_LABELS = [
  {
    key: LABELS.NO_DATES,
    label: 'no dates',
    color: '#fb8c00'
  },
  {
    key: LABELS.NO_CANDIDATE_NUMBER,
    label: 'no CDN',
    color: '#888a95'
  },
  {
    key: LABELS.RESERVATION_ERROR,
    label: 'reservation error',
    color: '#b80000'
  },
  {
    key: LABELS.ACCEPTANCE_ERROR,
    label: 'acceptance error',
    color: '#520000'
  },
  {
    key: LABELS.EXCHANGE_ERROR,
    label: 'exchange error',
    color: '#8a0e67'
  },
  {
    key: LABELS.IN_QUEUE,
    label: 'in queue',
    color: '#cfe815'
  },
  {
    key: LABELS.IS_IN_EXCHANGE_QUEUE,
    label: 'exchange queue',
    color: '#fc03b6'
  },
  {
    key: LABELS.NEW_RESERVE,
    label: 'new reserve',
    color: '#78dfa6'
  },
  {
    key: LABELS.NEW_EXCHANGE,
    label: 'new exchange',
    color: '#249a59'
  },
  {
    key: LABELS.IS_MIJN_RESERVATION,
    label: 'mijn reservation',
    color: '#3c16e9'
  },
  {
    key: LABELS.NEED_APPROVAL,
    label: 'need approval',
    color: '#EB5A8E'
  },
  {
    key: LABELS.HAS_APPROVAL,
    label: 'has approval',
    color: '#A570DD'
  }
];

export const CANDIDATES_LICENSE_TYPES_COLORS = {
  [LicenseTypesEnum.AUTO]: '#fd827d',
  [LicenseTypesEnum.MOTOR]: '#8fcaf9',
  [LicenseTypesEnum.SCOOTER]: '#efd896',
};
