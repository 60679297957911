import api from '@/services/api/api-storage';

export default {
  namespaced: true,

  state: () => ({
    items: null
  }),

  getters: {
    items: state => state.items
  },

  mutations: {
    set(state, items) {
      state.items = items;
    },

    updateItems(state, items) {
      if (!state.items) return;
      for (let field in items) {
        state.items[field] = items[field];
      }
    }
  },

  actions: {
    async get({ state, commit }, fields) {
      try {
        let _fields = {};
        fields.forEach(field => _fields[field] = null);
        let res = await api.get({
          data: _fields
        });
        commit('set', res);
        return res;
      } catch (error) {
        console.log(error);
        return Promise.reject(error);
      }
    },

    async set({ commit }, fields) {
      await api.set({ data: fields });
      commit('updateItems', fields);
    }
  }
};