export default class crmPerms {
  constructor(params = {superUser: false, avaliablePerms: []}) {
    this.avaliablePerms = params.avaliablePerms
    this.superUser = params.superUser
  }

  has(perm) {
    if (!this.avaliablePerms) return false
    if (this.superUser) return true
    if (this.avaliablePerms.includes(perm)) return true
    return false
  }
}
