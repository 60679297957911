import API from "@/services/api/api-labels"

export default class LabelService {
  static async list() {
    let res = await API.list()
    return res
  }

  static async create(data) {
    let res = await API.post(data)
    return res
  }

  static async remove(ID) {
    let res = await API.remove(ID)
    return res
  }

  static async update(ID, data) {
    let res = await API.update(ID, data)
    return res
  }
}