import ROUTE_NAMES from '@/app/admin/router/router-const';
import { CRM_PERMS } from '@/util/const';

export default [
  {
    path: 'applications',
    component: () => import('../TaskApplicationsPage.vue'),
    name: ROUTE_NAMES.TASK_APPLICATIONS,
    meta: {
      perms: CRM_PERMS.APPLICATIONS_TASK_LIST
    },
  }
];
