import { SelectBase } from "@/util/selector"

export const CM_DATES_ARG = {
  DATE: 'date',
  ID: 'id',
}

export default class CMDateModel {
  constructor(item = {}) {
    this.date = item[CM_DATES_ARG.DATE]
  }
}

export class CMDateSelectable extends SelectBase {
  constructor(item = {}) {
    super()
    this.item = item
    this.date = item.date
    this.ID = item.ID
    this.candidatesCount = item.candidatesCount
    this.topCbrQueueCount = item.topCbrQueueCount
    this.mijnCbrQueueCount = item.mijnCbrQueueCount
    this.topCbrExchangeQueueCount = item.topCbrExchangeQueueCount
    this.topCbrReserveQueueCount = item.topCbrReserveQueueCount
    this.will_come_candidates_count = item.will_come_candidates_count
    this.will_not_come_candidates_count = item.will_not_come_candidates_count
    this.maxSlots = item.maxSlots
    this.maxStudents = item.maxStudents
    this.slotsCount = item.slotsCount
    this.name = item.name
    this.has_candidates_needs_new_payment = item.has_candidates_needs_new_payment
    this.candidates_course_not_paid_count = item.candidates_course_not_paid_count
    this.reservable_license_types = item.reservable_license_types
    this.autoCounts = item.autoCounts
    this.motorCounts = item.motorCounts
    this.scooterCounts = item.scooterCounts
  }
}
