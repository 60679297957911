<template lang="pug">
  .header
    .header__wrapper
      slot
        portal-target(name="toolbar-start")
        portal-target(name="toolbar-title")
        .header__search
          portal-target(name="toolbar-search")
        portal-target(name="toolbar-end")
</template>

<script>
export default {
  name: 'Toolbar'
}
</script>

<style lang="scss">
.header {
  display: flex;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  min-height: 48px;
  background-color: $toolbar-bg;
  box-shadow: 0 1px 0 0 #F4F7F9;

  &__wrapper {
    display: inline-flex;
    align-items: center;
    flex: 1;
    padding: 0 15px;
    height: 100%;
  }

  &__search {
    flex: 1;
  }

  &__title {
    padding-right: 20px;
    color: $title;
    font-family: $font-title;
  }

  .head-btn {
    padding: 5px 10px;
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background-color: #F4F7F9;
    transition: all 0.15s ease-in-out;

    &:hover {
      border: 1px solid $label-color !important;
      background-color: #F4F7F9 !important;

      .label-title {
        color: $label-color !important;
      }
    }

    .app-button__wrapper {
      color: #888A95;
      text-transform: uppercase;
      font-weight: normal;
      font-size: 13px;
    }
  }
}

</style>
