import UserDTO from '@/DTO/userDTO';
import UserAPI from '@/services/api/api-user';
import auth from '@/services/CRMinitAuth';

export default {
  namespaced: true,

  state: () => ({
    current: null,
    sidebar: true
  }),

  getters: {
    current: state => state.current,
    sidebar: state => state.sidebar
  },

  mutations: {
    current: state => state.current,

    set(state, user) {
      state.current = new UserDTO(user);
    },

    clear(state) {
      state.current = null;
    },

    TOGGLE_SIDEBAR(state) {
      state.sidebar = !state.sidebar;
    }
  },

  actions: {
    async login({commit}) {
      try {
        let res = await auth.login();
        commit('set', res);
        return res;
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async current({commit}) {
      try {
        let res = await UserAPI.current();
        commit('set', res);
        return res;
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async logout({commit}) {
      try {
        let res = await auth.logout();
        commit('clear');
        return res;
      } catch (error) {
        console.log(error);
        return Promise.reject(error);
      }
    },

    changeSidebarState({commit}) {
      commit('TOGGLE_SIDEBAR');
    }
  },
};
