import api from '@/services/api/api-driving-schools';

export default {
  namespaced: true,

  state: () => ({
    items: null,
    list: null
  }),

  getters: {
    items: state => state.items,
    list: state => state.list,
  },

  mutations: {
    set_items(state, items) {
      state.items = items;
    },
    set_list(state, items) {
      state.list = items;
    },
  },

  actions: {
    async list({ state, commit }, params) {
      try {
        if (params && params.cache && state.list) return state.list;
        let res = await api.list();
        commit('set_list', res);

        res.sort(function (a, b) {
          if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
          if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
          return 0;
        });

        return res;
      } catch (error) {
        console.log(error);
        return Promise.reject(error);
      }
    },

    async items({ state, commit }, params) {
      try {
        if (params && params.cache && state.items) return state.items;
        let res = await api.items();

        res.sort(function (a, b) {
          if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
          if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
          return 0;
        });

        commit('set_items', res);
        return res;
      } catch (error) {
        console.log(error);
        return Promise.reject(error);
      }
    },
  }
};
