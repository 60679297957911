function translateErrorText(errMsg) {
  if (typeof errMsg !== "string") {
    return errMsg;
  }

  if (errMsg.includes("502 Bad Gateway")) {
    return "failed to connect to server, try again later";
  }

  if (errMsg.includes("etwork") && errMsg.includes("rror")) {
    return "failed to connect to server, try again later";
  }

  if (errMsg.includes("imeout") && errMsg.includes("xceed")) {
    return "failed to connect to server, try again later";
  }

  if (errMsg.includes("valid") && errMsg.includes("nection")) {
    return "failed to connect to server, try again later";
  }

  return errMsg;
}

export default {
  errResult(code, message) {
    return {
      status: "ERR",
      error: {
        code: code,
        message: message
      }
    };
  },

  processError(err) {
    let res = {
      status: "ERR",
      error: {
        code: -1,
        message: "unknown error"
      }
    };
    if (!err) {
      return res;
    }

    if ("response" in err && err.response) {
      let message = err.response.data;
      if (!message) {
        message = translateErrorText(err.response.statusText);
      }

      if (typeof message !== "string") {
        // error in forms
        let {detail, non_field_errors, ...fields} = message;
        if (Object.keys(fields).length) {
          res.invalid_fields = fields;
        }
        // non field errors
        if (non_field_errors || detail) {
          res.non_field_errors = non_field_errors || detail;
        }
        if (detail || non_field_errors) {
          res.error.code = err.response.status
          res.error.message = detail || non_field_errors;
          return res;
        }
      }

      res.error = {
        code: err.response.status,
        message: translateErrorText(message)
      };

      // overwrite the error code if authentication failed or access is denied
      if ([401, 403, 404].includes(err.response.status)) {
        res.error.code = err.response.status;
      }

      return res;
    }

    if ("message" in err && err.message) {
      res.error.message = translateErrorText(err.message);
      return res;
    }

    res.error.message = translateErrorText(err);

    return res;
  }
};
