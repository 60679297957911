import auth from '@/services/CRMinitAuth';

const getCoursesBy = query =>
  auth.get(`${process.env.VUE_APP_CRM_API}common/courses/`, {
    params: query
  });

const get = ID =>
  auth.get(`${process.env.VUE_APP_CRM_API}common/courses/${ID}/`);

const patch = (ID, data) =>
  auth.patch(`${process.env.VUE_APP_CRM_API}common/courses/${ID}/`, data);

const courseListDetailed = query =>
  auth.get(`${process.env.VUE_APP_CRM_API}common/courses/detailed/`, {
    params: query
  });

const allCourses = query =>
  auth.get(`${process.env.VUE_APP_CRM_API}common/courses/all_courses/`, {
    params: query
  });

const meetingSpacesList = query =>
  auth.get(`${process.env.VUE_APP_CRM_API}common/meeting_spaces/`, {
    params: query
  });

const meetingSpaceInfo = meetingSpaceID =>
  auth.get(`${process.env.VUE_APP_CRM_API}common/meeting_spaces/${meetingSpaceID}/`);

const createCourseDay = payload =>
  auth.post(`${process.env.VUE_APP_CRM_API}common/course_day/`, payload);

const getCourseDay = id =>
  auth.get(`${process.env.VUE_APP_CRM_API}common/course_day/${id}/`);

const updateCourseDay = (id, payload, params) =>
  auth.put(`${process.env.VUE_APP_CRM_API}common/course_day/${id}/`, payload, params);

const patchCourseDay = (id, payload, params) =>
  auth.patch(`${process.env.VUE_APP_CRM_API}common/course_day/${id}/`, payload, params);

const removeCourseDay = id =>
  auth.delete(`${process.env.VUE_APP_CRM_API}common/course_day/${id}/`);

const courseDays = payload =>
  auth.get(`${process.env.VUE_APP_CRM_API}common/course_day/`, {
    params: payload
  });

const courseDaysFuture = payload =>
  auth.get(`${process.env.VUE_APP_CRM_API}common/course_day/future/`, {
    params: payload
  });

const courseDaysPast = payload =>
  auth.get(`${process.env.VUE_APP_CRM_API}common/course_day/past/`, {
    params: payload
  });

const bulkSetZoomRegistrant = (courseID, data) =>
  auth.post(`${process.env.VUE_APP_CRM_API}common/course_day/${courseID}/bulk_zoom_set_registrant/`, data);

const bulkSetZoomAllRegistrant = (courseID) =>
  auth.post(`${process.env.VUE_APP_CRM_API}common/course_day/${courseID}/bulk_zoom_set_all_registrant/`);

const bulkSendZoomInvite = (courseID, data) =>
  auth.post(`${process.env.VUE_APP_CRM_API}common/course_day/${courseID}/resend_zoom_invite/`, data);

const candidatesGetZoomPresentie = (courseID) =>
  auth.get(`${process.env.VUE_APP_CRM_API}common/course_day/${courseID}/set_presentie_from_zoom_webinar/`);

// Checklist
const getChecklists = (payload) =>
  auth.get(`${process.env.VUE_APP_CRM_API}common/checklist/`, {params: payload});

const getChecklist = (checklistID) =>
  auth.get(`${process.env.VUE_APP_CRM_API}common/checklist/${checklistID}`);

const createChecklist = (payload) =>
  auth.post(`${process.env.VUE_APP_CRM_API}common/checklist/`, payload);

const updateChecklist = (checklistID, payload) =>
  auth.put(`${process.env.VUE_APP_CRM_API}common/checklist/${checklistID}/`, payload);

const changeChecklist = (checklistID, payload) =>
  auth.patch(`${process.env.VUE_APP_CRM_API}common/checklist/${checklistID}/`, payload);

const deleteChecklist = (checklistID) =>
  auth.delete(`${process.env.VUE_APP_CRM_API}common/checklist/${checklistID}/`);

const checkListLogs = (query) =>
  auth.get(`${process.env.VUE_APP_CRM_API}common/checklist/logs/`, {params: query});

// Course photo
const uploadCoursePhoto = (courseID, payload, params) =>
  auth.patch(`${process.env.VUE_APP_CRM_API}common/course_day/${courseID}/`, payload, params);

export default {
  get,
  patch,
  getCoursesBy,
  courseListDetailed,
  allCourses,
  removeCourseDay,
  meetingSpacesList,
  meetingSpaceInfo,
  createCourseDay,
  getCourseDay,
  updateCourseDay,
  courseDays,
  courseDaysFuture,
  courseDaysPast,
  patchCourseDay,
  bulkSetZoomRegistrant,
  bulkSetZoomAllRegistrant,
  bulkSendZoomInvite,
  candidatesGetZoomPresentie,
  getChecklists,
  getChecklist,
  createChecklist,
  updateChecklist,
  changeChecklist,
  deleteChecklist,
  uploadCoursePhoto,
  checkListLogs,
};
