import MockRepo from "./__mocks__/auth-repo-local-storage"
class AuthRepoLocalStorage {
  /**
   *
   */
  store(key, val) {
    if (val === null) {
      localStorage.removeItem(key);
      return;
    }

    if (val) {
      val = JSON.stringify(val);
    }
    localStorage.setItem(key, val);
  }

  /**
   *
   */
  get(key) {
    let val = localStorage.getItem(key);
    if (val) {
      val = JSON.parse(val);
    }
    return val;
  }

  /**
   *
   */
  remove(key) {
    localStorage.removeItem(key);
  }
}

let Repo = process.env.VUE_APP_MOCK_INTERNAL_REPO ? MockRepo : AuthRepoLocalStorage

export default Repo
