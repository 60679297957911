import ROUTE_NAMES from '@/app/admin/router/router-const';
import { CRM_PERMS } from '@/util/const';

export default [
  {
    path: 'feedback',
    component: () => import('../FeedbackPage.vue'),
    name: ROUTE_NAMES.FEEDBACK,
    meta: {
      perms: CRM_PERMS.FEEDBACK
    },
  }
];
