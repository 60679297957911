import LabelService from '@/app/admin/models/LabelService';
import { LabelBase } from '@/app/admin/models/LabelModel';

export default {
  namespaced: true,

  state: () => ({
    items: null
  }),

  getters: {
    items: state => state.items
  },

  mutations: {
    set(state, items) {
      state.items = items.map(item => new LabelBase(item));
    },

    add(state, item) {
      state.items.push(new LabelBase(item));
    },

    update(state, payload) {
      let index = state.items.findIndex(item => item.ID === payload.ID);
      state.items[index].name = payload.name;
      state.items[index].color = payload.color;
    },

    remove(state, ID) {
      let index = state.items.findIndex(item => item.ID === ID);
      state.items.splice(index, 1);
    }
  },

  actions: {
    async list({ state, commit }, params) {
      try {
        if (params && params.cache && state.items) return state.items;
        let res = await LabelService.list();
        commit('set', res);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
};
